import { MULTI_COMPLETED, MULTI_PENDING, StepStatus } from 'state/slices/ui/form'

export interface StepRowProps {
  index: number
  label: string
  status: StepStatus
}

export function StepRow({ index, label, status }: StepRowProps) {
  const isCompleted = status === MULTI_COMPLETED
  const isPending = status === MULTI_PENDING

  const completedClass = isCompleted ? 'completed' : ''
  const pendingClass = isPending ? 'pending' : ''

  return (
    <div className="step-row">
      <div className="step-row-details">
        <p className="step-row-index">Step {index + 1}</p>
        <p className="step-row-label">{label}</p>
      </div>
      <p className={`step-row-status ${completedClass} ${pendingClass}`}>{status}</p>
    </div>
  )
}
