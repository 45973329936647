import { Aptos, Ed25519PublicKey, Hex, TransactionPayload } from '@aptos-labs/ts-sdk'
import {
  dismissNotifyThrow,
  logAndToastError,
  logToastThrow,
  toastLoad,
  toastSuccess
} from './toast'
import {
  AccountArgs,
  PacketResponse,
  SignAndSubmitTransactionCallback,
  SPortfolio,
  SPosition,
  TransactionArgs,
  UnifiedVault,
  WaitArgs
} from 'state/types'
import { InputTransactionData } from '@aptos-labs/wallet-adapter-react'
import { eMessage } from './format'
import { SUPPLY_COLLATERAL, TxTypeBroker, WITHDRAW } from 'state/thunks/doTxBroker'
import { isEmptyOrNil } from './account'
import { dispatch } from 'state/store'
import { fetchWalletBalances } from 'state/thunks/fetchWalletBalances'
import { setTxSuccessState, TxAction } from 'state/slices/ui/transaction'
import { CoinTypeWithDecimals } from 'state/slices/app/vaults'
import { FormTab } from 'state/slices/ui/form'

export const getDepositPrices = async (
  aptos: Aptos,
  vaultAddr: string,
  coinX: string,
  coinY: string,
  binStep: string,
  rootAddr: string
) => {
  const data = await aptos.view({
    payload: {
      function: `${rootAddr}::vault::get_deposit_prices_bin_id`,
      typeArguments: [coinX, coinY, binStep],
      functionArguments: [vaultAddr]
    }
  })

  return data
}

export function pubKeyFromHex(hex: string) {
  const cleanHex = hex.startsWith('0x') ? hex.slice(2) : hex
  const publicKeyBytes = Buffer.from(cleanHex, 'hex')
  const publicKey = new Ed25519PublicKey(publicKeyBytes)
  return publicKey
}

export async function checkRegisteredToken({
  address,
  coinType,
  aptos,
  signAndSub
}: {
  address: string
  coinType: string
  aptos: Aptos
  signAndSub: SignAndSubmitTransactionCallback
}) {
  const accountArgs: AccountArgs = {
    accountAddress: address
  }

  const resources = await aptos.getAccountResources(accountArgs)
  const hasCoinRegistered = resources.find((r) => r.type.includes(coinType))
  if (!hasCoinRegistered) {
    toastLoad('Registering token...')
    const registerTx: InputTransactionData = {
      sender: address,
      data: {
        function: '0x1::managed_coin::register',
        typeArguments: [coinType],
        functionArguments: []
      }
    }
    try {
      await signAndSub(registerTx)
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Token Not Registered', eMessage(e))
    }
  }
}

export async function fetchLendV2Packet(
  txArgs: TransactionArgs,
  url: string
): Promise<PacketResponse> {
  const response = await fetch(`${url}/brokers/lend/v2`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(txArgs)
  })

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  return response.json() as Promise<PacketResponse>
}

export async function fetchRedeemV2Packet(
  txArgs: TransactionArgs,
  url: string
): Promise<PacketResponse> {
  const response = await fetch(`${url}/brokers/redeem/v2`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(txArgs)
  })

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  return response.json() as Promise<PacketResponse>
}

export async function fetchPacket({
  txType,
  txArgs,
  url
}: {
  txType: TxTypeBroker
  txArgs: TransactionArgs
  url: string
}) {
  let packet: PacketResponse
  toastLoad(`Fetching ${txType} packet...`)

  try {
    switch (txType) {
      case SUPPLY_COLLATERAL:
        packet = await fetchLendV2Packet(txArgs, url)
        break
      case WITHDRAW:
        packet = await fetchRedeemV2Packet(txArgs, url)
        break
    }
    return packet
  } catch (e: any) {
    console.error(e)
    dismissNotifyThrow(`${txType} Packet Not Created`, eMessage(e))
  }
}

export function superLendV2Ix(
  packet: Uint8Array,
  coinType: string,
  sender: string,
  rootAddress: string
): InputTransactionData {
  // Martian wallet does not know how to handle a Uint8Array
  // argument, so we're forced to convert it to a plain Array
  const packetArgument = Array.from(packet)

  return {
    sender,
    data: {
      function: `${rootAddress}::entry_public::lend_v2`,
      typeArguments: [coinType],
      functionArguments: [packetArgument]
    }
  }
}

export function superRedeemV2Ix(
  packet: Uint8Array,
  coinType: string,
  sender: string,
  rootAddress: string
): InputTransactionData {
  // Marian wallet does not know how to handle a Uint8Array
  // argument, so we're forced to convert it to a plan Array
  const packetArgument = Array.from(packet)

  return {
    sender,
    data: {
      function: `${rootAddress}::entry_public::redeem_v2`,
      typeArguments: [coinType],
      functionArguments: [packetArgument]
    }
  }
}

export async function signAndSubmitter({
  packet,
  coinType,
  txType,
  address,
  rootAddress,
  signAndSub
}: {
  packet: PacketResponse
  coinType: string
  txType: TxTypeBroker
  address: string
  rootAddress: string
  signAndSub: SignAndSubmitTransactionCallback
}) {
  toastLoad('Signing transaction...')
  let ix: InputTransactionData
  try {
    const packetHex = Hex.fromHexString(packet.packet)
    const ar = packetHex.toUint8Array()

    switch (txType) {
      case SUPPLY_COLLATERAL:
        ix = superLendV2Ix(ar, coinType, address, rootAddress)
        break
      case WITHDRAW:
        ix = superRedeemV2Ix(ar, coinType, address, rootAddress)
        break
      default:
        throw new Error(`Invalid transaction type: ${txType}`)
    }
    const hash = await signAndSub(ix)
    return hash
  } catch (e: any) {
    console.error(e)
    dismissNotifyThrow('Transaction Not Submitted', eMessage(e))
  }
}

export async function signAndSubmitterBasic({
  signAndSub,
  ixData,
  options
}: {
  signAndSub: SignAndSubmitTransactionCallback
  ixData: InputTransactionData
  options?: { gasUnitPrice: number; maxGasAmount: number }
}) {
  toastLoad('Signing transaction...')
  try {
    const hash = options ? await signAndSub({ ...ixData, options }) : await signAndSub(ixData)
    return hash
  } catch (e: any) {
    console.error(e)
    dismissNotifyThrow('Transaction Not Submitted', eMessage(e))
  }
}

export async function fetchPortfolioWithRisk(
  address: string,
  API_URL: string
): Promise<SPortfolio | null> {
  try {
    const p = await fetch(`${API_URL}/portfolios/${address}`)
    if (!p) {
      console.log('Portfolio not found')
      return null
    }
    const portfolio = await p.json()
    const nextCollaterals = portfolio.collaterals.map((c: any) => {
      return {
        ...c,
        scaledAmount: Number(c.scaledAmount)
      }
    })
    const nextLiabilities = portfolio.liabilities.map((l: any) => {
      return {
        ...l,
        scaledAmount: Number(l.scaledAmount)
      }
    })
    return {
      ...portfolio,
      collaterals: nextCollaterals,
      liabilities: nextLiabilities
    }
  } catch (e: any) {
    return null
  }
}

export type BasicPosition = {
  /**
   * Type string
   */
  instrumentId: string
  /**
   * Raw token units
   */
  amount: string
}

export function buildCurrentPortfolioBasicState(freshPortfolioState: SPortfolio): {
  collaterals: BasicPosition[]
  liabilities: BasicPosition[]
} {
  if (
    isEmptyOrNil(freshPortfolioState) ||
    !freshPortfolioState.collaterals ||
    !freshPortfolioState.liabilities
  ) {
    return { collaterals: [], liabilities: [] }
  }

  const mapToBasicPosition = (position: SPosition) => ({
    instrumentId: position.instrument.name,
    amount: position.amount
  })

  return {
    collaterals: freshPortfolioState.collaterals.map(mapToBasicPosition),
    liabilities: freshPortfolioState.liabilities.map(mapToBasicPosition)
  }
}

export const ECH_USDC_APTOS =
  '0xb129c938e4d1a0c2ae6be6c4589dfcfaba6f87be41c6ef65a1b2f10fa36366ac::tokens::USDC'
export const ECH_USDT_APTOS =
  '0xb129c938e4d1a0c2ae6be6c4589dfcfaba6f87be41c6ef65a1b2f10fa36366ac::tokens::USDT'
export const ECH_DAI_APTOS =
  '0xb129c938e4d1a0c2ae6be6c4589dfcfaba6f87be41c6ef65a1b2f10fa36366ac::tokens::DAI'
export const ECH_WETH_APTOS =
  '0xb129c938e4d1a0c2ae6be6c4589dfcfaba6f87be41c6ef65a1b2f10fa36366ac::tokens::WETH'

export const ECH_FAUCET_COIN_TYPES = [ECH_USDC_APTOS, ECH_USDT_APTOS, ECH_DAI_APTOS, ECH_WETH_APTOS]

export const ECH_DECIMALS_MAP: Record<string, number> = {
  [ECH_USDC_APTOS]: 6,
  [ECH_USDT_APTOS]: 6,
  [ECH_DAI_APTOS]: 8,
  [ECH_WETH_APTOS]: 8
}

export async function echelonFaucet(
  aptos: Aptos,
  address: string,
  signAndSub: SignAndSubmitTransactionCallback,
  coinTypeWithDecimals: CoinTypeWithDecimals[]
) {
  toastLoad('Requesting faucet...')

  try {
    const response = await signAndSub({
      data: {
        function:
          '0xb129c938e4d1a0c2ae6be6c4589dfcfaba6f87be41c6ef65a1b2f10fa36366ac::faucet::mintAll',
        typeArguments: ECH_FAUCET_COIN_TYPES,

        functionArguments: []
      },
      sender: address
    })

    const args: WaitArgs = {
      transactionHash: response.hash,
      options: {
        checkSuccess: true
      }
    }

    console.log('faucet response', response)
    const result = await aptos.waitForTransaction(args)
    console.log('faucet result', result)
    toastSuccess(`Faucet success!`)
    dispatch(fetchWalletBalances({ address, coinTypeWithDecimals, aptos }))
  } catch (e: any) {
    console.error(e)
    logAndToastError('Faucet Not Completed', eMessage(e))
  }
}

export type ResourceType = `${string}::${string}::${string}`

export async function getCustomTokenBalance(
  aptos: Aptos,
  address: string,
  tokenType: ResourceType
) {
  try {
    const resource = await aptos.getAccountResource({
      accountAddress: address,
      resourceType: tokenType
    })

    if (resource && 'data' in resource) {
      // You might need to adjust this based on the actual structure of the resource
      const data = resource.data as { balance?: string }
      return data.balance ? BigInt(data.balance) : BigInt(0)
    }
    return BigInt(0)
  } catch (error) {
    console.error(`Error fetching balance for ${tokenType}:`, error)
    return BigInt(0)
  }
}

export async function updateSuccessStateAndWait({
  hash,
  aptos,
  action
}: {
  hash: string
  aptos: Aptos
  action: string
}) {
  toastLoad('Awaiting finality...')
  try {
    const args: WaitArgs = {
      transactionHash: hash,
      options: {
        checkSuccess: true
      }
    }
    const result = await aptos.waitForTransaction(args)
    console.log(`${action} result`, result)
    toastSuccess(`${action} success!`)
    return result
  } catch (e: any) {
    console.error(e)
    logToastThrow('Transaction Not Completed', e)
  }
}

export function normalizeHexAddress(address: string): string {
  // Check if it starts with 0x and has 64 characters after prefix
  if (address.startsWith('0x') && address.length === 66) {
    return address
  }

  // Check if it starts with 0x but is missing the leading zero
  if (address.startsWith('0x') && address.length === 65) {
    return `0x0${address.slice(2)}`
  }

  throw new Error('Invalid hex address format')
}
