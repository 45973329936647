import {
  formatCurrency,
  formatPercentageCompact,
  onlyNumbers,
  prettyTokenBal
} from 'toolbox/format'
import { TokenPill } from 'components/common/TokenPill'
import { SimpleInput, SimpleInputProps } from 'components/common/SimpleInput'

export interface TxInputProps {
  logo: string
  ticker: string
  inputVal: string
  walletBalance: number
  price: number
  positionBalance: number
  isDeposit: boolean
  setInputVal: (val: string) => void
}

export function TxInput({
  logo,
  ticker,
  inputVal,
  setInputVal,
  walletBalance,
  positionBalance,
  isDeposit,
  price
}: TxInputProps) {
  const inputDetail = formatCurrency(Number(inputVal) * price)
  const easyInputValues = [0.25, 0.5, 0.75, 1]

  const handleEasyInput = (value: number) => {
    const useBal = isDeposit ? walletBalance : positionBalance
    const newVal = (useBal * value).toString()
    setInputVal(newVal)
  }

  const simpleInputProps: SimpleInputProps = {
    inputVal,
    setInputVal,
    inputDetail,
    logo,
    ticker
  }

  return (
    <>
      <SimpleInput {...simpleInputProps} />
      <div className="tx-form-input-actions">
        <div className="action-btns">
          {easyInputValues.map((value) => (
            <button key={value} className="btn small-grey" onClick={() => handleEasyInput(value)}>
              {formatPercentageCompact(value)}
            </button>
          ))}
        </div>
        <div className="wallet-balance">
          <p className="wallet-balance-label">Wallet</p>
          <p className="wallet-balance-value">{prettyTokenBal(walletBalance)}</p>
        </div>
      </div>
    </>
  )
}
