import usdtIcon from 'assets/logos/usdt.svg'
import aptIcon from 'assets/logos/apt.svg'

export function LayerToken() {
  return (
    <div className="layer-token">
      <img src={usdtIcon} alt="USDT" />
      <img src={aptIcon} alt="APT" />
    </div>
  )
}
