import { Modal } from 'components/modals/Modal'
import { useAppSelector } from 'state/hooks'
import {
  selectTxError,
  selectTxSuccess,
  setTxErrorState,
  setTxSuccessState
} from 'state/slices/ui/transaction'
import { isEmptyOrNil } from 'toolbox/account'
// import TransferSuccess from 'components/txForm/TransferSuccess'
import { ErrorModal } from 'components/modals/ErrorModal'
import MobileModal from './MobileModal'
import { closeWallet, selectWalletOpen } from 'state/slices/ui/wallet'
import WalletModal from 'components/wallet/WalletModal'
import {
  selectActiveVault,
  selectFormOpen,
  selectTokenSelectOpen,
  setActiveVaultState,
  setFormInputState,
  setFormOpenState,
  setTokenSelectOpenState
} from 'state/slices/ui/form'
import { TxForm } from 'components/form/TxForm'
import { VaultDetails } from 'components/explore/VaultDetails'
import { FullModal } from './FullModal'
import { selectExploreVaultDetailsOpen, setVaultDetailsOpen } from 'state/slices/ui/explore'
import { dispatch } from 'state/store'
import { clearBrokerHistory } from 'state/slices/app/brokerHistory'
import { TokenSelectList } from 'components/common/TokenSelectList'
import { selectUnifiedVaults } from 'state/slices/app/vaults'

export function AppModals() {
  const txSuccessState = useAppSelector(selectTxSuccess)
  const txSuccessOpen = !isEmptyOrNil(txSuccessState)
  const txError = useAppSelector(selectTxError)
  const isErrorOpen = !isEmptyOrNil(txError)
  const walletOpen = useAppSelector(selectWalletOpen)
  const formOpen = useAppSelector(selectFormOpen)
  const vaultDetailsOpen = useAppSelector(selectExploreVaultDetailsOpen)
  const tokenSelectOpen = useAppSelector(selectTokenSelectOpen)
  const vaults = useAppSelector(selectUnifiedVaults)

  const closeDetails = () => {
    dispatch(setVaultDetailsOpen(false))
    dispatch(clearBrokerHistory())
    setActiveVaultState(null)
    setFormInputState('')
  }

  return (
    <>
      {/* <Modal open={txSuccessOpen} onClose={() => setTxSuccessState(null)} transition={'bot-up'}>
        <TransferSuccess />
      </Modal> */}
      {/* <Modal open={isErrorOpen} onClose={() => setTxErrorState(null)} transition={'bot-up'}>
        <ErrorModal {...txError} />
      </Modal> */}
      <Modal
        open={tokenSelectOpen}
        onClose={() => setTokenSelectOpenState(false)}
        transition={'bot-up'}
        highZIndex={true}>
        <TokenSelectList vaults={vaults} />
      </Modal>
      <Modal open={walletOpen} onClose={closeWallet} transition={'top-down'} highZIndex={true}>
        <WalletModal />
      </Modal>
      <Modal open={formOpen} onClose={() => setFormOpenState(false)} transition={'bot-up'}>
        <TxForm />
      </Modal>

      <FullModal open={vaultDetailsOpen} onClose={closeDetails}>
        <VaultDetails />
      </FullModal>
    </>
  )
}
