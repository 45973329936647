import Explore from 'pages/Explore'
import { Route, Routes } from 'react-router-dom'
import Splash from './Splash'
import { Earn } from 'pages/Earn'
import { Rewards } from 'pages/Rewards'

export function Routing() {
  const routes = (
    <Routes>
      <Route path="/" element={<Splash />} />
      <Route path="/explore" element={<Explore />} />
      <Route path="/earn" element={<Earn />} />
      <Route path="/rewards" element={<Rewards />} />
    </Routes>
  )

  return routes
}
