import { dispatch } from 'state/store'
import { fetchBrokers } from './thunks/fetchBrokers'
import { fetchPortfolio } from './thunks/fetchPortfolio'
import { fetchWalletBalances } from './thunks/fetchWalletBalances'
import { setDisconnectMenuState } from './slices/ui/wallet'
import { resetPortfolio } from './slices/user/portfolio'
import { resetWalletBalances } from './slices/user/walletBalances'
import { Aptos } from '@aptos-labs/ts-sdk'
import { CoinTypeWithDecimals } from './slices/app/vaults'
import { resetStakedBalances } from './slices/user/stakedBalances'
import { resetRewardsBalances } from './slices/user/rewardsBalances'

export function fetchAppData({
  url,
  aptos,
  echelonAddress,
  nodeUrl
}: {
  url: string
  aptos: Aptos
  echelonAddress: string
  nodeUrl: string
}) {
  //TODO: app fetch and inspect others
  console.log('fetching app data...')
  // dispatch(fetchVaults({ nodeUrl }))
  dispatch(fetchBrokers({ url }))
  // dispatch(fetchMeta())
}

export function fetchUserData(
  address: string,
  coinTypeWithDecimals: CoinTypeWithDecimals[],
  url: string,
  aptos: Aptos,
  echelonRootAddress: string
) {
  console.log('fetching user data...')
  dispatch(fetchPortfolio({ address, url }))
  dispatch(fetchWalletBalances({ address, coinTypeWithDecimals, aptos }))
}
export function postTransactionRefresh(
  address: string,
  coinTypeWithDecimals: CoinTypeWithDecimals[],
  url: string,
  aptos: Aptos,
  echelonAddress: string,
  nodeUrl: string
) {
  console.log('posting transaction refresh...')
  fetchAppData({ url, aptos, echelonAddress, nodeUrl })
  fetchUserData(address, coinTypeWithDecimals, url, aptos, echelonAddress)
}

export function resetUserData() {
  setDisconnectMenuState(false)
  dispatch(resetPortfolio())
  dispatch(resetWalletBalances())
  dispatch(resetStakedBalances())
  dispatch(resetRewardsBalances())
}
