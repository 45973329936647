import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  FormTab,
  selectActiveVault,
  selectBuiltFormTabs,
  selectFormInput,
  selectIsManage,
  selectIsReview,
  selectTab,
  setFormInputState,
  setFormOpenState,
  setIsReviewState
} from 'state/slices/ui/form'
import { DEPOSIT_TAB } from 'state/slices/ui/form'
import { TxInput, TxInputProps } from './TxInput'
import { formatPercentage, prettyTokenBal, scaleDown } from 'toolbox/format'
import LineItemList from 'components/common/LineItemList'
import CloseButton from 'components/common/CloseButton'
import { getWalletBalance, selectLoadedWalletBalances } from 'state/slices/user/walletBalances'
import { TxInputReview } from './TxInputReview'
import { Network, useWallet } from '@aptos-labs/wallet-adapter-react'
import { selectTxStatus } from 'state/slices/ui/transaction'
import Tabs from 'components/common/Tabs'
import { doTxBroker, tabToType, TxReqPayloadBroker } from 'state/thunks/doTxBroker'
import { ECHELON, LIQUIDSWAP, MOVEPOSITION, UnifiedVault } from 'state/types'
import { logAndToastError } from 'toolbox/toast'
import { VaultRow } from 'components/explore/VaultRow'
import { Aptos } from '@aptos-labs/ts-sdk'
import { useEnvironment } from 'App'
import { AptosConfig } from '@aptos-labs/ts-sdk'
import { selectBrokersAsUnified } from 'state/slices/app/brokers'
import { selectCoinTypesWithDecimals } from 'state/slices/app/vaults'

export interface QuickDepositFormProps {
  activeVault: UnifiedVault | null
}

export function QuickDepositForm({ activeVault }: QuickDepositFormProps) {
  const dispatch = useAppDispatch()
  const { account, signAndSubmitTransaction } = useWallet()
  const balances = useAppSelector(selectLoadedWalletBalances)
  const formTab = DEPOSIT_TAB
  const inputVal = useAppSelector(selectFormInput)
  const isReview = useAppSelector(selectIsReview)
  const brokers = useAppSelector(selectBrokersAsUnified)
  const isTxPending = useAppSelector(selectTxStatus) === 'pending'
  const { config } = useEnvironment()
  const coinTypeWithDecimals = useAppSelector(selectCoinTypesWithDecimals)

  //TODO: fix quick deposit position
  const myPosition = 0

  const assetTicker = activeVault?.meta.displayName || ''
  const logoURL = activeVault?.meta.iconURL || ''
  const coinType = activeVault?.coinType || ''
  const assetPrice = activeVault?.assetPrice || 0
  const vaultType = activeVault?.meta.investmentType || ''

  const walletBalance = getWalletBalance(balances, coinType)

  const hasInput = Number(inputVal) > 0

  const inputProps: TxInputProps = {
    logo: logoURL,
    ticker: assetTicker,
    inputVal,
    setInputVal: setFormInputState,
    walletBalance,
    price: assetPrice,
    positionBalance: myPosition,
    isDeposit: true
  }

  const overBalance = Number(inputVal) > walletBalance
  const overBalanceMessage = 'Exceeds wallet balance'

  const disabled = !hasInput || isTxPending || overBalance

  const action = 'Deposit and Stake'

  const buttonText = !hasInput
    ? 'Enter amount'
    : isTxPending
    ? 'Transaction pending...'
    : overBalance
    ? overBalanceMessage
    : !isReview
    ? 'Review'
    : action

  const onClick = () => {
    if (isReview && activeVault) {
      const aptosConfig = new AptosConfig({
        network: config.network as Network,
        fullnode: config.aptosNodeUrl,
        faucet: config.aptosFaucetUrl
      })

      const aptos = new Aptos(aptosConfig)

      const commonPayload = {
        formTab: formTab as FormTab,
        address: account?.address as string,
        amount: Number(inputVal),
        vault: activeVault,
        signAndSub: signAndSubmitTransaction,
        aptos: aptos,
        envNetwork: config.network,
        API_URL: config.positionApiUrl,
        nodeUrl: config.aptosNodeUrl,
        coinTypeWithDecimals
      }

      switch (vaultType) {
        //TODO: liquidswap quick deposit
        case MOVEPOSITION:
          dispatch(
            doTxBroker({
              ...commonPayload,
              rootAddr: config.positionAptosRootAddress,
              echelonRootAddress: config.echelonContractAddress
            })
          )
          break
        default:
          logAndToastError(`Invalid vault type: ${vaultType}`, '')
      }
    } else {
      setIsReviewState(true)
    }
  }

  const recommendedVaultDisplay = activeVault && (
    <div className="recommended">
      <p className="recommended-title">Recommended vault</p>
      <VaultRow vault={activeVault} minimal />
    </div>
  )
  return (
    <div className="tx-form quick-deposit">
      <h3>Quick Deposit</h3>

      {isReview ? (
        <TxInputReview {...inputProps} />
      ) : (
        <div className="tx-form-input-container">
          <TxInput {...inputProps} />
        </div>
      )}
      {recommendedVaultDisplay}

      <div className="tx-form-buttons">
        {isReview && (
          <button
            className="btn rounded primary type-2 type-grey"
            onClick={() => setIsReviewState(false)}>
            Back
          </button>
        )}
        <button className="btn full-w rounded primary type-2" disabled={disabled} onClick={onClick}>
          {buttonText}
        </button>
      </div>
    </div>
  )
}
