import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from './reducers/root'

// Define RootState before using it
export type RootState = ReturnType<typeof rootReducer>

export const configureTheStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: 'test'
        }
      })
  })

export const store = configureTheStore()
export const { dispatch } = store

// RootState is now defined above, so we don't need to redefine it here
export type AppStore = ReturnType<typeof configureTheStore>
export type AppDispatch = typeof store.dispatch
