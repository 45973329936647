import { createAsyncThunk } from '@reduxjs/toolkit'
import { logToastThrow, toastLoad, toastSuccess } from 'toolbox/toast'
import { SignAndSubmitTransactionCallback } from 'state/types'
import { postTransactionRefresh } from 'state/fetch'
import { txStatusIdle, txStatusPending } from 'state/slices/ui/transaction'
import { Aptos, Network, PublicKey } from '@aptos-labs/ts-sdk'
import { signAndSubmitterBasic, updateSuccessStateAndWait } from 'toolbox/aptos-util'
import { CoinTypeWithDecimals } from 'state/slices/app/vaults'
import { AptosTx } from 'toolbox/aptosTx'

export interface TxReqPayloadClaim {
  address: string
  signAndSub: SignAndSubmitTransactionCallback
  aptos: Aptos
  envNetwork: Network
  API_URL: string
  nodeUrl: string
  coinTypeWithDecimals: CoinTypeWithDecimals[]
  stakeAddress: string
  vaultsAddress: string
  publicKey: PublicKey
}

export const doClaimTx = createAsyncThunk(
  'rewards/doClaimTx',
  async (payload: TxReqPayloadClaim) => {
    toastLoad('Building transaction...')
    txStatusPending({ type: 'Claim', info: 'Signing transaction...' })

    const { envNetwork, aptos } = payload

    console.log('DO CLAIM TX PAYLOAD', payload)

    if (!envNetwork || !aptos) {
      logToastThrow(
        'Missing required system configuration',
        'Check the console for more information'
      )
    }

    const aptosTx = new AptosTx(payload.vaultsAddress, payload.stakeAddress)

    if (!payload.nodeUrl || !envNetwork || !aptos) {
      console.log('Env network: ', envNetwork)
      console.log('aptosSDK: ', aptos)
      logToastThrow(
        'Missing required system configuration',
        'Check the console for more information'
      )
    }

    const { address, API_URL } = payload
    if (!address) {
      logToastThrow(
        'Missing arguments',
        'The transaction request is missing required arguments, please try again'
      )
    }

    //TODO: remove hardcoded staking token
    const STAKING_TOKEN_HARD_CODE =
      '0xdaf28c5ba0736813bdd1a6d225ce787ee9f4ed22c3bfc660297a4e2a343d620b'

    try {
      const tx = aptosTx.claimIx({
        sender: address,
        stakingTokens: [STAKING_TOKEN_HARD_CODE]
      })
      console.log('claim tx', tx)

      let options = {
        gasUnitPrice: 100,
        maxGasAmount: 10000
      }

      try {
        // Build the transaction payload
        const simTx = await aptos.transaction.build.simple({
          sender: address,
          data: {
            function: `${payload.stakeAddress}::router::claim_rewards`,
            typeArguments: [],
            functionArguments: [[STAKING_TOKEN_HARD_CODE]]
          },
          options
        })

        // Simulate the transaction with proper authentication
        const simulated = await aptos.transaction.simulate.simple({
          signerPublicKey: payload.publicKey,
          transaction: simTx
        })

        if (!simulated[0] || simulated[0].success !== true) {
          logToastThrow('Error claiming rewards', simulated[0]?.vm_status)
          txStatusIdle()
          return
        }

        options.gasUnitPrice = Number(simulated[0].gas_unit_price)
        options.maxGasAmount = Number(simulated[0].max_gas_amount)
        console.log('simulated claim tx', simulated)
      } catch (e: any) {
        console.error(e)
        logToastThrow('Error claiming rewards', e.message)
        txStatusIdle()
      }

      try {
        const hash = await signAndSubmitterBasic({
          signAndSub: payload.signAndSub,
          ixData: tx,
          options
        })

        const claimResult = await updateSuccessStateAndWait({
          hash: hash.hash,
          aptos,
          action: 'Claim'
        })
        console.log('claim result', claimResult)
        toastSuccess('Transaction completed!')

        postTransactionRefresh(
          address,
          payload.coinTypeWithDecimals,
          API_URL,
          aptos,
          '',
          payload.nodeUrl
        )
        txStatusIdle()
      } catch (e: any) {
        console.error(e)
        logToastThrow('Error claiming rewards', e.message)
        txStatusIdle()
      }
    } catch (e: any) {
      console.error(e)
      logToastThrow('Error claiming rewards', e.message)
      txStatusIdle()
    }
  }
)
