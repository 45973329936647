import { createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { fetchPortfolio } from 'state/thunks/fetchPortfolio'
import { createSelector } from 'reselect'
import { isEmptyOrNil } from 'toolbox/account'
import { SBroker, UnifiedPosition } from 'state/types'
import { scaleDown } from 'toolbox/format'

export interface BalanceMap {
  [key: string]: number
}

export interface FormattedPositions {
  collaterals: BalanceMap
  liabilities: BalanceMap
}

export const calcLendRate = (borrowRate: number, interestFeeRate: number, utilization: number) => {
  return borrowRate * (1 - interestFeeRate) * utilization
}

type State = Loadable<any>
export const initialState = initLoadable<any>(null)

const portfolio = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    resetPortfolio: (state: State) => {
      state.value = null
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolio.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchPortfolio.pending, (state) => {
      state.status = 'busy'
    })

    builder.addCase(fetchPortfolio.rejected, (state) => {
      state.status = 'errored'
    })
  }
})

export const { resetPortfolio } = portfolio.actions
export default portfolio.reducer
export const selectPortfolio = (s: RootState) => s.user.portfolio.value
export const selectPortfolioBusy = (s: RootState) => s.user.portfolio.status === 'busy'
export const selectPortfolioLoaded = (s: RootState) => s.user.portfolio.loadedOnce
export const selectPortfolioErrored = (s: RootState) => s.user.portfolio.status === 'errored'

export const selectPositions = createSelector(
  [selectPortfolio, selectPortfolioLoaded],
  (portfolio, loaded) => {
    if (!loaded || !portfolio) {
      return null
    }
    return { collaterals: portfolio?.collaterals, liabilities: portfolio?.liabilities }
  }
)

export const selectFormattedPositions = createSelector([selectPositions], (positions) => {
  if (!positions) {
    return { collaterals: {}, liabilities: {} }
  }
  const c = positions?.collaterals
  const l = positions?.liabilities
  const collaterals: BalanceMap = {}
  const liabilities: BalanceMap = {}

  if (!isEmptyOrNil(c)) {
    c.forEach((c: any) => {
      collaterals[c.instrument.name] = c.amount
    })
  }
  if (!isEmptyOrNil(l)) {
    l.forEach((l: any) => {
      liabilities[l.instrument.name] = l.amount
    })
  }
  const formattedPositions: FormattedPositions = {
    collaterals,
    liabilities
  }
  return formattedPositions
})

export const selectCollateralPositionByBroker = (brokerName: string) =>
  createSelector([selectFormattedPositions], (positions) => {
    return positions?.collaterals[brokerName] || 0
  })

export const calcUnderlyingFromNoteBalance = (
  noteBalance: number,
  broker: SBroker
): UnifiedPosition => {
  const decimals = broker.underlyingAsset.decimals || 0
  const balance = scaleDown(noteBalance * broker.depositNoteExchangeRate || 0, decimals)
  const assetPrice = broker.underlyingAsset.price || 0
  const value = balance * assetPrice || 0
  return {
    balance,
    value
  }
}
