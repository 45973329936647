import { UnifiedVault } from 'state/types'
import { TokenSelectRow } from './TokenSelectRow'
import {
  selectQuickDepositInput,
  setQuickDepositInputState,
  setTokenSelectOpenState
} from 'state/slices/ui/form'
import { useAppSelector } from 'state/hooks'
import SearchBar, { SearchBarProps } from './SearchBar'
import { getWalletBalance, selectLoadedWalletBalances } from 'state/slices/user/walletBalances'
import { filterVaultsByInput } from 'toolbox/regex'
import CloseButton from './CloseButton'

export interface TokenSelectListProps {
  vaults: UnifiedVault[]
}

export function TokenSelectList({ vaults }: TokenSelectListProps) {
  const inputVal = useAppSelector(selectQuickDepositInput)
  const balances = useAppSelector(selectLoadedWalletBalances)
  const searchProps: SearchBarProps = {
    value: inputVal,
    update: setQuickDepositInputState,
    placeHolder: 'Search tokens',
    forceOpen: true
  }

  const filteredVaults = filterVaultsByInput(vaults, inputVal)

  const [tokensWithBalance, tokensWithoutBalance] = filteredVaults?.reduce(
    (acc, v) => {
      if (!v.meta) return acc

      const walletBalance = getWalletBalance(balances, v.coinType)
      const uniqueKey = `${v.coinType}-${v.meta.id}`
      const row = {
        element: <TokenSelectRow key={uniqueKey} vault={v} walletBalance={walletBalance} />,
        balance: walletBalance
      }
      if (walletBalance > 0) {
        acc[0].push(row)
      } else {
        acc[1].push(row.element)
      }
      return acc
    },
    [[], []] as [{ element: JSX.Element; balance: number }[], JSX.Element[]]
  )

  const sortedTokensWithBalance = tokensWithBalance
    .sort((a, b) => b.balance - a.balance)
    .map((item) => item.element)

  const hasTokensWithBalance = sortedTokensWithBalance.length > 0

  return (
    <div className="token-select-list-container">
      <CloseButton cb={() => setTokenSelectOpenState(false)} />
      <h3 className="token-select-list-header">Search tokens</h3>
      <SearchBar {...searchProps} />
      <hr className="hr" />
      <div className="token-select-list-inner">
        {hasTokensWithBalance && (
          <>
            <p className="token-select-list-label">Tokens with wallet balance</p>
            {sortedTokensWithBalance}
          </>
        )}
        <p className="token-select-list-label">All tokens</p>
        {tokensWithoutBalance}
      </div>
    </div>
  )
}
