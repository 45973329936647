import { ReactNode, useEffect } from 'react'

export function Modal(props: {
  children: ReactNode
  open: boolean
  onClose: () => void
  transition?: string
  highZIndex?: boolean
}) {
  const { open, onClose, transition, highZIndex } = props

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onClose])

  const live = open ? 'live' : ''
  const ani = transition ? transition : ''

  return (
    <div
      className={`modal ${open ? 'is-active' : ''} ${ani} ${highZIndex ? 'high-z-index' : ''}`}
      id="modal">
      <div className="modal-background" onClick={onClose}></div>
      <div className={`modal-content ${live} ${ani}`}>{props.children}</div>
    </div>
  )
}

export default Modal
