import { gql } from '@apollo/client'

export const GET_VAULTS = `
  query GetVaults {
    vaults {
      id
      name
    }
  }
`

export const GET_ALL_TOKEN_META = `
 query ListMonitorVaults {
    listMonitorVaults {
      items {
        name
        address
        displayName
        memberTokenRatio
        baseTokenValue
        pendingDeposits
        pendingDepositsRatio
        vaultStrength
        tvl
        sevenDaysChange
        scarceTokenValue
        scarceTokenMarketCap
        vaultIRR
        vaultIrrAllTx
        isHodlVault
        needRebalanceFrom
        lastRebalance
        isInverted
        wallPrice
        poolAddress
        scarceTokenPriceFromVault
        targetVaultStrength
      }
    }
  }
`

export const GET_CANOPY_METADATA_STRING = `
  query MyQuery {
    listCanopyMetadata {
      items {
        riskScore
        paused
        id
        investmentType
        networkType
        displayName
        chainId
        description
        iconURL
        labels
        networkAddress
        priority
        rewardPools
        additionalMetadata {
          item
          key
        }
      }
    }
  }
`

export const GET_CANOPY_METADATA = gql(GET_CANOPY_METADATA_STRING)

export const GET_INDEXER_VAULTS_STRING = `
query MyQuery {
  vaults {
    bin_step
    coin_x_address
    coin_y_address
    collection
    created_timestamp
    deposit_count
    fee_apr_1_day_u18
    fee_apr_30_day_u18
    fee_apr_3_day_u18
    fee_apr_7_day_u18
    holder_count
    id
    is_x_deposit
    lp_apr_1_day_u18
    lp_apr_30_day_u18
    lp_apr_3_day_u18
    lp_apr_7_day_u18
    owner_address
    rebalance_count
    snapshot_count
    token_id_creator
    token_id_name
    total_fees
    total_shares
    total_value_locked_in_y_u18
    total_value_locked_x
    total_value_locked_y
    transaction_block_height
    transaction_version
    vault_address
    withdraw_count
  }
}`

export const GET_INDEXER_VAULTS = gql(GET_INDEXER_VAULTS_STRING)
