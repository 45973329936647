import { createAsyncThunk } from '@reduxjs/toolkit'

export type FetchPortfolioPayload = {
  address: string
  url: string
}

const MAX_ATTEMPTS = 5

export const fetchPortfolio = createAsyncThunk(
  'portfolios/fetchAll',
  async (payload: FetchPortfolioPayload): Promise<any> => {
    console.log('Fetching portfolio for address: ', payload.address)

    if (!payload.url) {
      throw new Error('API_URL not set')
    }

    if (!payload.address) {
      throw new Error('Address not set')
    }

    try {
      const response = await fetch(`${payload.url}/portfolios/${payload.address}`)

      const portfolio = await response.json()
      console.log('Portfolio: ', portfolio)

      return portfolio
    } catch (error) {
      console.error('Error fetching portfolio:', error)
      throw error
    }
  }
)
