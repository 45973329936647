import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { formatPublicKeyDisplay } from 'toolbox/format'
import copy from 'assets/icons/copy.svg'
import { useEffect, useRef } from 'react'

export interface WalletDisconnectProps {
  disconnect: () => void
  closeModal: () => void
}

function WalletDisconnect({ disconnect, closeModal }: WalletDisconnectProps) {
  const { account } = useWallet()
  const address = account?.address
  const modalRef = useRef<HTMLDivElement | null>(null)

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    navigator.clipboard.writeText(address as string)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        closeModal()
      }
    }

    document.addEventListener('mouseup', handleClickOutside)

    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [closeModal])

  return (
    <>
      <div className="disconnect-modal" ref={modalRef} onClick={(e) => e.stopPropagation()}>
        <div className="inner" onClick={(e) => e.stopPropagation()}>
          <div className="add-bar">
            <p>{formatPublicKeyDisplay(address)}</p>
            <button onClick={handleButtonClick}>
              <img src={copy} alt="copy" />
            </button>
          </div>
          <hr className="hr" />
          <div className="disconnect">
            <button className="btn full-w transparent border rounded" onClick={disconnect}>
              Disconnect
            </button>
          </div>
          <div className="links">
            <a href="/" className="a-link grey" rel="noopener noreferrer" target="_blank">
              Canopy
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default WalletDisconnect
