import { getWalletBalance } from 'state/slices/user/walletBalances'
import { useAppSelector } from 'state/hooks'
import { selectLoadedWalletBalances } from 'state/slices/user/walletBalances'
import { LIQUIDSWAP, UnifiedVault } from 'state/types'
import { LineItem, LineItemProps } from 'components/common/LineItem'
import { prettyTokenBal } from 'toolbox/format'
import { doTxLiquidStakeOnly, TxReqPayloadLiquidStakeOnly } from 'state/thunks/doTxLiquidStakeOnly'
import { STAKE_ONLY_TAB } from 'state/slices/ui/form'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { Aptos, AptosConfig, Ed25519PublicKey } from '@aptos-labs/ts-sdk'
import { useEnvironment } from 'App'
import { Network } from 'aptos'
import { selectCoinTypesWithDecimals } from 'state/slices/app/vaults'
import { dispatch } from 'state/store'

export function LPStake({ activeVault }: { activeVault: UnifiedVault }) {
  const { account, signAndSubmitTransaction } = useWallet()
  const { meta } = activeVault || {}
  const isLiquidSwap = meta?.investmentType === LIQUIDSWAP
  const balances = useAppSelector(selectLoadedWalletBalances)
  const lpTokenId = isLiquidSwap ? activeVault.ichiVault?.token_id_name || '' : ''
  const lpTokenBalance = getWalletBalance(balances, lpTokenId)
  const hasLpBalance = lpTokenBalance > 0
  const showHideClass = hasLpBalance ? 'show' : 'hide'
  const displayName = meta?.displayName || ''
  const { config } = useEnvironment()
  const coinTypeWithDecimals = useAppSelector(selectCoinTypesWithDecimals)

  const lpLineItem: LineItemProps = {
    label: `${displayName} LP`,
    value: prettyTokenBal(lpTokenBalance)
  }

  const aptosConfig = new AptosConfig({
    network: config.network as Network,
    fullnode: config.aptosNodeUrl,
    faucet: config.aptosFaucetUrl
  })

  const aptos = new Aptos(aptosConfig)

  const txReqPayloadLiquid: TxReqPayloadLiquidStakeOnly = {
    formTab: STAKE_ONLY_TAB,
    address: account?.address as string,
    amount: lpTokenBalance,
    vault: activeVault,
    signAndSub: signAndSubmitTransaction,
    aptos: aptos,
    envNetwork: config.network,
    API_URL: config.positionApiUrl,
    echelonRootAddress: config.echelonContractAddress,
    nodeUrl: config.aptosNodeUrl,
    publicKey: account?.publicKey
      ? new Ed25519PublicKey(account?.publicKey as string)
      : new Ed25519PublicKey('0x1234567890123456789012345678901234567890123456789012345678901234'),
    coinTypeWithDecimals,
    stakeAddress: config.stakeAddress
  }

  const onClick = () => {
    dispatch(doTxLiquidStakeOnly(txReqPayloadLiquid))
  }

  return (
    <div className={`lp-stake ${showHideClass}`}>
      <LineItem {...lpLineItem} />
      <p className="lp-stake-description">Your wallet has LP tokens. Stake them to get yield.</p>
      <button className="btn primary type-a rounded" onClick={onClick}>
        Stake
      </button>
    </div>
  )
}
