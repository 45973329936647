import { formatCurrencyPrecise } from 'toolbox/format'

export interface TxInputClaimProps {
  inputVal: number
}

export function TxInputClaim({ inputVal }: TxInputClaimProps) {
  return (
    <div className="tx-input-review">
      <p className="label">You receive</p>
      <p className="review-bal">{formatCurrencyPrecise(Number(inputVal))}</p>
    </div>
  )
}
