import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { formatPublicKeyDisplay } from 'toolbox/format'
import chev from 'assets/icons/down-chev-primary.svg'

export interface SignInProps {
  open: () => void
  connected: boolean
  disconnect: () => void
}

function SignIn({ open, connected, disconnect }: SignInProps) {
  const { account } = useWallet()
  const address = account?.address
  const formatted = formatPublicKeyDisplay(address)

  return connected ? (
    <button
      className="btn secondary with-chev gap desk-only-no-width disconnect-btn"
      onClick={disconnect}>
      <p className="disconnect-button">{formatted}</p>
      <img src={chev} alt="disconnect" />
    </button>
  ) : (
    <button className="btn primary-dark desk-only-no-width" onClick={open}>
      Connect wallet
    </button>
  )
}

export default SignIn
