import { useTheme } from 'state/ThemeContext'
import { useEffect } from 'react'

export function ThemeToggle() {
  const { theme, toggleTheme } = useTheme()

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme)
  }, [theme])

  return (
    <div className="theme-toggle"></div>
    // <button className="btn secondary" onClick={toggleTheme}>
    //   {theme === 'light' ? 'Dark' : 'Light'} Mode
    // </button>
  )
}
