import { UnifiedVault } from 'state/types'

export function sanitizeInput(input: string): string {
  // Remove leading and trailing whitespace
  const trimmedInput = input.trim()

  // Remove potentially dangerous characters (e.g., <, >, and script tags)
  const sanitizedInput = trimmedInput.replace(/</g, '').replace(/>/g, '')

  return sanitizedInput
}

export function filterVaultsByInput(vaults: UnifiedVault[], input: string) {
  const sanitizedInput = sanitizeInput(input).toLowerCase()
  const filteredVaults = vaults.filter(
    (v) =>
      v.coinType.toLowerCase().includes(sanitizedInput) ||
      v.assetName.toLowerCase().includes(sanitizedInput) ||
      v.meta.displayName.toLowerCase().includes(sanitizedInput) ||
      v.meta.investmentType.toLowerCase().includes(sanitizedInput) ||
      v.meta.id.toLowerCase().includes(sanitizedInput)
  )

  return filteredVaults.length > 0 ? filteredVaults : vaults
}
