import logomark from 'assets/app/logomark.svg'
import { Link, useLocation } from 'react-router-dom'
import {
  selectDisconnectOpen,
  disconnectWallet,
  openWalletSignIn,
  setDisconnectMenuState
} from 'state/slices/ui/wallet'
import SignIn from 'components/common/SignIn'
import WalletDisconnect from 'components/wallet/WalletDisconnect'
import { useAppSelector } from 'state/hooks'
import { Network, useWallet } from '@aptos-labs/wallet-adapter-react'
import { useEffect, useRef, useState } from 'react'
import { logAndToastError } from 'toolbox/toast'
import burgerImg from 'assets/icons/burger.svg'
import { BurgerNav } from './BurgerNav'
import walletImg from 'assets/icons/wallet.svg'
import { useEnvironment } from 'App'
import { APTOS, aptosDevConfig, MOVEMENT, movementDevConfig, TESTNET } from 'state/envConfigs'
import Dropdown from 'components/common/Dropdown'
import { Aptos, AptosConfig } from '@aptos-labs/ts-sdk'
import { selectBrokersAsUnified } from 'state/slices/app/brokers'
import { ThemeToggle } from 'components/common/ThemeToggle'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

function TopNav() {
  const { config, updateConfig } = useEnvironment()
  const query = useQuery()
  const queryString = query.toString()
  const { disconnect, connected, account, signAndSubmitTransaction } = useWallet()
  const isDisconnectOpen = useAppSelector(selectDisconnectOpen)
  const { pathname } = useLocation()
  const targetElementRef = useRef(null)
  const [isBurgerOpen, setIsBurgerOpen] = useState(false)
  const isTestnet = config.network === TESTNET
  const brokers = useAppSelector(selectBrokersAsUnified)

  const aptosConfig = new AptosConfig({
    network: config.network as Network,
    fullnode: config.aptosNodeUrl,
    faucet: config.aptosFaucetUrl
  })

  const aptos = new Aptos(aptosConfig)

  const splash = pathname === '/'
  const explore = pathname.includes('/explore')
  const earn = pathname.includes('/earn')
  const rewards = pathname.includes('/rewards')
  const selectedStyle = 'selected'

  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 30)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const tryDisconnect = async () => {
    try {
      disconnect()
      disconnectWallet()
    } catch (e: any) {
      logAndToastError('Wallet', e.message)
    }
  }

  const hasWallet = connected && account?.address

  const configSwitch = (option: string) => {
    if (connected) {
      disconnect()
    }
    if (option === APTOS) {
      updateConfig(aptosDevConfig)
    } else if (option === MOVEMENT) {
      updateConfig(movementDevConfig)
    }
  }

  const homeLink = connected ? `/explore?${queryString}` : `/?${queryString}`

  return (
    <>
      <div className={`nav-wrapper ${isScrolled ? 'scrolled' : ''}`} ref={targetElementRef}>
        <nav className="top-nav">
          <div className="left-logo">
            <Link to={homeLink}>
              <img src={logomark} alt="canopy" className="logomark" />
            </Link>
          </div>
          {/* <div className="burger-button" onClick={() => setIsBurgerOpen(!isBurgerOpen)}></div> */}
          <div className="mid-links piece">
            <Link
              to={`/earn?${queryString}`}
              className={`a-link bg-grey-select ${earn ? selectedStyle : ''}`}>
              Earn
            </Link>
            <Link
              to={`/explore?${queryString}`}
              className={`a-link bg-grey-select ${explore ? selectedStyle : ''}`}>
              Explore
            </Link>
            <Link
              to={`/rewards?${queryString}`}
              className={`a-link bg-grey-select ${rewards ? selectedStyle : ''}`}>
              All rewards
            </Link>
          </div>
          <div className="buttons">
            <button
              className="mobile-only wallet-img"
              onClick={
                connected ? () => setDisconnectMenuState(!isDisconnectOpen) : openWalletSignIn
              }>
              <img src={walletImg} alt="wallet" />
            </button>
            <button onClick={() => setIsBurgerOpen(true)} className="mobile-only burger-button tsp">
              <img src={burgerImg} alt="menu" />
            </button>
            {isBurgerOpen && <BurgerNav cb={() => setIsBurgerOpen(false)} />}
            <ThemeToggle />
            {/* {!!isTestnet && (
              <Dropdown
                options={[APTOS, MOVEMENT]}
                callback={configSwitch}
                selectedOption={config.chain}
              />
            )} */}

            <SignIn
              open={openWalletSignIn}
              connected={connected}
              disconnect={
                isDisconnectOpen ? () => null : () => setDisconnectMenuState(!isDisconnectOpen)
              }
            />
            {isDisconnectOpen && (
              <WalletDisconnect
                disconnect={tryDisconnect}
                closeModal={() => setDisconnectMenuState(false)}
              />
            )}
          </div>
        </nav>
      </div>
      <div className="nav-spacer" />
    </>
  )
}

export default TopNav
