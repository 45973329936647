import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { fetchRewardsBalances } from 'state/thunks/fetchRewardsBalances'

export interface BalanceMap {
  [key: string]: number
}

type State = Loadable<BalanceMap>
export const initialState = initLoadable<BalanceMap>({})

const rewardsBalances = createSlice({
  name: 'rewardsBalances',
  initialState,
  reducers: {
    resetRewardsBalances: (state: State) => {
      state.value = {}
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRewardsBalances.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchRewardsBalances.pending, (state) => {
      state.status = 'busy'
    })

    builder.addCase(fetchRewardsBalances.rejected, (state) => {
      state.status = 'errored'
    })
  }
})

export const { resetRewardsBalances } = rewardsBalances.actions
export default rewardsBalances.reducer
export const selectRewardsBalances = (s: RootState) => s.user.rewardsBalances.value
export const selectRewardsBalancesBusy = (s: RootState) => s.user.rewardsBalances.status === 'busy'
export const selectRewardsBalancesLoaded = (s: RootState) => s.user.rewardsBalances.loadedOnce
export const selectRewardsBalancesErrored = (s: RootState) =>
  s.user.rewardsBalances.status === 'errored'

export const selectRewardsBalance = createSelector(
  [
    selectRewardsBalances,
    selectRewardsBalancesLoaded,
    (_state: RootState, tokenKey: string) => tokenKey
  ],
  (rewardsBalances, loaded, tokenKey) => {
    if (!loaded || !rewardsBalances) {
      return 0
    }
    return rewardsBalances[tokenKey] ?? 0
  }
)
