import { useState } from 'react'
import {
  ComposedChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line
} from 'recharts'
import { formatCurrency, formatDateSimple, formatPercentage } from 'toolbox/format'
import { SimpleSpinner } from 'components/common/SimpleSpinner'
import { isEmptyOrNil } from 'toolbox/account'
// import {
//   selectBrokerUtilizationHistoryByName,
//   timeTabs
// } from '../../state/slices/app/brokerHistories'
// import TimeFilterTabs from 'components/common/TimeFilterTabs'
import { useAppSelector } from 'state/hooks'
import { ChartLabel } from './ChartLabel'
import { selectTVLHistory } from 'state/slices/app/brokerHistory'

export interface RateHistory {
  timestamp: number
  tvl: number
  apr: number
}

export function BarLineRateHistory({ isLoaded = true }: { isLoaded: boolean }) {
  const [activeData, setActiveData] = useState<RateHistory | null>(null)
  //   const [activeTab, setActiveTab] = useState('1M')
  //   const numberOfDays = timeTabs.find((tab) => tab.label === activeTab).days
  //   const utilData = useAppSelector((state) =>
  //     selectBrokerUtilizationHistoryByName(state, name, numberOfDays)
  //   )

  const utilData = useAppSelector(selectTVLHistory)

  const handleMouseMove = (data: any) => {
    if (data.activePayload) {
      // Update state with the data of the hovered point
      setActiveData(data.activePayload[0].payload)
    }
  }

  const handleMouseLeave = () => {
    setActiveData(null)
  }

  //   const filteredAndSortedData = useMemo(() => {
  //     const filteredData = utilData.filter(
  //       (d) => d.timestamp * 1000 > Date.now() - numberOfDays * 24 * 60 * 60 * 1000
  //     )

  // const sortedData = filteredData.sort((a, b) => a.timestamp - b.timestamp)

  //     return sortedData
  //   }, [utilData, numberOfDays])

  if (isEmptyOrNil(utilData) || !isLoaded)
    return (
      <div className="chart-container">
        <SimpleSpinner />
      </div>
    )

  const chartData = utilData.map((item) => ({
    time: formatDateSimple(item.timestamp / 1000 - 86400),
    tvl: item.tvl,
    apr: item.apr
  }))

  const lastItem = utilData[utilData.length - 1]

  //   const timeTabsProps = {
  //     activeTab,
  //     setActiveTab
  //   }
  return (
    <div className="chart-container">
      {/* <div className="points-line">
        <div className="cluster-points">{pointsDisplay}</div>
        <TimeFilterTabs {...timeTabsProps} />
      </div> */}

      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={chartData}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          barCategoryGap={2}
          barGap={0}
          // barSize={100}
          margin={{
            top: 0,
            bottom: 0
          }}>
          <XAxis
            dataKey="time"
            axisLine={false}
            tickLine={true}
            allowDataOverflow={false}
            interval="preserveEnd"
          />
          <YAxis
            domain={[0, 'dataMax']}
            yAxisId="left"
            orientation="left"
            stroke="#D9D9D9"
            hide={true}
            allowDataOverflow={false}
            dataKey={'tvl'}
            padding={{ top: 150 }}
          />
          <YAxis
            domain={[0, 'dataMax']}
            yAxisId="right"
            orientation="right"
            stroke="black"
            hide={true}
            padding={{ bottom: 100, top: 50 }}
            allowDataOverflow={false}
          />

          <Tooltip
            content={
              <ChartLabel
                value={`${formatPercentage(activeData?.apr ?? 0)}%`}
                secondValue={formatCurrency(activeData?.tvl ?? 0)}
                labels={{
                  value: 'Annualized return',
                  secondValue: 'TVL'
                }}
              />
            }
          />
          <Bar
            dataKey="tvl"
            yAxisId="left"
            fill="#D9D9D9"
            activeBar={<Rectangle fill="#4fbb87" stroke="#4fbb87" />}
          />
          <Line dataKey="apr" yAxisId="right" stroke="#4fbb87" dot={false} strokeWidth={1.5} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
