import { RewardsPillTotal } from 'components/common/RewardsPillTotal'
import { ClaimForm } from 'components/form/ClaimForm'
import { useAppSelector } from 'state/hooks'
import { selectAptPrice } from 'state/slices/app/brokers'
import { selectRewardsBalances } from 'state/slices/user/rewardsBalances'

export function Rewards() {
  const rewardsBalances = useAppSelector(selectRewardsBalances)
  const currentReward = rewardsBalances['0xa']
  const aptPrice = useAppSelector(selectAptPrice) || 0
  const currentRewardValue = currentReward * aptPrice
  const normal = currentRewardValue / 10 ** 8

  return (
    <div className="rewards-page">
      <div className="rewards-inner">
        {/* <RewardsPill rewardsList={rewardsBalances} prices={prices} tokenName="CANOPY" />
         */}
        <RewardsPillTotal value={normal} />
        <ClaimForm value={normal} tokenBalance={currentReward} />
      </div>
    </div>
  )
}
