import CloseButton from 'components/common/CloseButton'
import WalletsList from './WalletsList'
import { closeWallet } from 'state/slices/ui/wallet'
import { useWallet } from '@aptos-labs/wallet-adapter-react'

function WalletModal() {
  const { connected } = useWallet()
  return (
    <div className="wallet-modal">
      <CloseButton cb={closeWallet} />
      <h3>{connected ? 'Connected' : 'Select Wallet'}</h3>
      <WalletsList />
    </div>
  )
}

export default WalletModal
