import { isEmptyOrNil } from 'toolbox/account'
import arrow from 'assets/icons/arrow-right.svg'

export interface LineItemProps {
  label: string
  value: string
  tooltip?: string
  next?: string
  action?: () => void
  simColor?: string
  currentColor?: string
  slippage?: boolean
  secondaryValue?: string
}

export function LineItem({
  label,
  value,
  tooltip,
  next,
  action,
  slippage,
  simColor,
  currentColor,
  secondaryValue
}: LineItemProps) {
  const hasNext = !isEmptyOrNil(next)
  const isClickable = action ? 'clickable' : ''
  const thin = tooltip ? 'thin-lined' : ''

  return (
    <div className="line-item">
      <div className={`is-flex ${thin}`}>
        <div className={`label tip-box `}>
          <p>{label}</p>
          {tooltip && <span className="tip-value">{tooltip}</span>}
        </div>
      </div>

      <div className="values">
        {action && !hasNext ? (
          <p className={`value ${isClickable}`} onClick={action}>
            {value}
          </p>
        ) : (
          <p className={`value ${currentColor}`}>{value}</p>
        )}
        {!!secondaryValue && <p className={`value secondary`}>{secondaryValue}</p>}
        {!!hasNext && <img src={arrow} alt="arrow" />}
        {!!hasNext && <p className={`value nextVal ${simColor}`}>{next}</p>}
      </div>
    </div>
  )
}
