export interface TokenPillProps {
  logo: string
  ticker: string
  clear?: boolean
}

export function TokenPill({ logo, ticker, clear }: TokenPillProps) {
  return (
    <div className={`token-pill ${clear ? 'clear' : ''}`}>
      <img src={logo} alt="token" />
      <p className="token-pill-name">{ticker}</p>
    </div>
  )
}
