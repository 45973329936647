import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resetUserData } from 'state/fetch'
import { RootState, dispatch } from 'state/store'
import { setVaultDetailsOpen } from './explore'
import { clearBrokerHistory } from '../app/brokerHistory'
import { setFormInputState } from './form'

export interface WalletState {
  disconnectOpen: boolean
  walletOpen: boolean
  stickyOn: boolean
}

export const initialState: WalletState = {
  disconnectOpen: false,
  walletOpen: false,
  stickyOn: false
}

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletOpen: (s) => {
      s.walletOpen = true
    },
    setWalletClosed: (s) => {
      s.walletOpen = false
    },
    setDisconnectMenu: (s, { payload }: PayloadAction<boolean>) => {
      s.disconnectOpen = payload
    },
    setStickyOn: (s) => {
      s.stickyOn = true
    },
    setStickyOff: (s) => {
      s.stickyOn = false
    }
  }
})

export default slice.reducer

//selectors
export const selectDisconnectOpen = (s: RootState) => s.ui.wallet.disconnectOpen
export const selectWalletOpen = (s: RootState) => s.ui.wallet.walletOpen
export const selectStickyOn = (s: RootState) => s.ui.wallet.stickyOn

//actions
const { setDisconnectMenu, setWalletOpen, setWalletClosed, setStickyOn, setStickyOff } =
  slice.actions

//dispatches

export const openWallet = () => {
  dispatch(setWalletOpen())
}

export const closeWallet = () => {
  dispatch(setWalletClosed())
}

export const setDisconnectMenuState = (open: boolean) => {
  dispatch(setDisconnectMenu(open))
}

export const setSticky = () => {
  dispatch(setStickyOn())
}

export const unsetSticky = () => {
  dispatch(setStickyOff())
}

//group actions
export const disconnectWallet = () => {
  resetUserData()
}

export const openWalletSignIn = () => {
  openWallet()
}
