import closeIcon from 'assets/icons/close.svg'
import closeWhite from 'assets/icons/close-white.svg'

export interface CloseButtonProps {
  cb: () => any
  white?: boolean
}

function CloseButton({ cb, white }: CloseButtonProps) {
  return (
    <img
      src={white ? closeWhite : closeIcon}
      alt="close"
      className={`close ${white ? 'white' : ''}`}
      onClick={cb}
    />
  )
}

export default CloseButton
