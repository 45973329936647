import { NetworkInfo } from '@aptos-labs/wallet-adapter-react'
import { Network } from '@aptos-labs/ts-sdk'

export interface EnvironmentConfig {
  vaultsAddress: string
  network: Network
  chain: string
  workerUrl: string
  echelonContractAddress: string
  aptosNodeUrl: string
  positionApiUrl: string
  positionAptosRootAddress: string
  stakeAddress: string
  aptosFaucetUrl?: string
}

export const TESTNET = Network.TESTNET
export const APTOS = 'aptos'
export const MOVEMENT = 'movement'

export const aptosDevConfig: EnvironmentConfig = {
  vaultsAddress: '0x2aa557d92dc24df8f2ac2e5226d964f479bb51ffba645c3f33efc75f16a1b353',
  network: TESTNET,
  chain: APTOS,
  workerUrl: 'http://localhost:8787/api/proxy',
  echelonContractAddress: '0xdaaf1cca3f702b3d94425e4f0a7bfb921142666846a916f5be91edf1f1911d4',
  aptosNodeUrl: 'https://fullnode.testnet.aptoslabs.com/v1',
  positionApiUrl: 'https://staging-superposition-k6tdfemj7a-uc.a.run.app',
  positionAptosRootAddress: '0x93305a1157665b3f837a2acf1de9f5018050775356b743684ee390797fbaf8d6',
  stakeAddress: '0x2532f3162a5ab00742b7d21a37550e11db04eb6c5b8169fee097233ab6ba18f6'
}

export const movementDevConfig: EnvironmentConfig = {
  vaultsAddress: '0x2aa557d92dc24df8f2ac2e5226d964f479bb51ffba645c3f33efc75f16a1b353',
  network: TESTNET,
  chain: MOVEMENT,
  workerUrl: 'http://localhost:8787/api/proxy',
  echelonContractAddress: '0x3dc5ef372359b6ab006bb517a8df5ca1b2c8ef35ce1ff45f7db8a44e2e43e856',
  aptosNodeUrl: 'https://aptos.testnet.suzuka.movementlabs.xyz/v1',
  positionApiUrl: 'https://superposition-staging-6adeoqkr4q-uc.a.run.app',
  positionAptosRootAddress: '0x55777b59548b69b7174cb988de90f3116afceb6be43f210acc99afc19ad5ddba',
  aptosFaucetUrl: 'https://faucet.testnet.suzuka.movementlabs.xyz',
  stakeAddress: '0x2532f3162a5ab00742b7d21a37550e11db04eb6c5b8169fee097233ab6ba18f6'
}

export interface NightlyNetworkInfo {
  chainId: number
  name: Network
  url: string
}

export const aptosTestnetWalletNetwork: NightlyNetworkInfo = {
  chainId: 2,
  name: Network.TESTNET,
  url: 'https://fullnode.testnet.aptoslabs.com/v1'
}

export const mvmtSuzukaWalletNetwork: NightlyNetworkInfo = {
  chainId: 27,
  name: Network.CUSTOM,
  url: 'https://aptos.testnet.suzuka.movementlabs.xyz/v1'
}

export const getWalletNetwork = (chain: string) => {
  if (chain === APTOS) {
    return aptosTestnetWalletNetwork
  } else if (chain === MOVEMENT) {
    return mvmtSuzukaWalletNetwork
  }
}
