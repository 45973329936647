import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, dispatch } from 'state/store'

export const ANNUALIZED_RETURN = 'Annualized return'
export const DAILY_EARNINGS = 'Daily earnings'
export const TVL = 'TVL'
export const WALLET_BALANCE = 'Wallet balance'
export const RISK_SCORE = 'Risk score'
export const POSITION_VALUE = 'Position value'

export const sortByOptions = [ANNUALIZED_RETURN, TVL, WALLET_BALANCE, RISK_SCORE, POSITION_VALUE]

export type SortBy = (typeof sortByOptions)[number]

export interface ExploreState {
  input: string
  sortBy: SortBy
  vaultDetailsOpen: boolean
  stickyOn: boolean
}

export const initialState: ExploreState = {
  input: '',
  sortBy: ANNUALIZED_RETURN,
  vaultDetailsOpen: false,
  stickyOn: false
}

const slice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    setInput: (s, a: PayloadAction<string>) => {
      s.input = a.payload
    },
    setSortBy: (s, a: PayloadAction<SortBy>) => {
      s.sortBy = a.payload
    },
    setVaultDetailsOpen: (s, a: PayloadAction<boolean>) => {
      s.vaultDetailsOpen = a.payload
    },
    setStickyOn: (s, a: PayloadAction<boolean>) => {
      s.stickyOn = a.payload
    }
  }
})

export default slice.reducer

export const selectExploreInput = (s: RootState) => s.ui.explore.input
export const selectExploreSortBy = (s: RootState) => s.ui.explore.sortBy
export const selectExploreVaultDetailsOpen = (s: RootState) => s.ui.explore.vaultDetailsOpen
export const selectExploreStickyOn = (s: RootState) => s.ui.explore.stickyOn
//dispatches
export const { setInput, setSortBy, setVaultDetailsOpen, setStickyOn } = slice.actions

export const setExploreInput = (p: string) => {
  dispatch(setInput(p))
}

export const setExploreSortBy = (p: SortBy) => {
  dispatch(setSortBy(p))
}

export const setExploreVaultDetailsOpen = (p: boolean) => {
  dispatch(setVaultDetailsOpen(p))
}

export const setExploreStickyOn = (p: boolean) => {
  dispatch(setStickyOn(p))
}
