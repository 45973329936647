import { LineItem, LineItemProps } from 'components/common/LineItem'
import { UnifiedVault } from 'state/types'
import { formatPercentage } from 'toolbox/format'

export function APRHover({ vault }: { vault: UnifiedVault }) {
  const { supplyAPR } = vault
  const rewardsAPR = 0
  const totalAPR = supplyAPR + rewardsAPR

  const totalItem: LineItemProps = {
    label: 'Total return',
    value: formatPercentage(totalAPR)
  }

  const feeAprItem: LineItemProps = {
    label: 'Strategy yield',
    value: formatPercentage(supplyAPR)
  }

  const rewardsAprItem: LineItemProps = {
    label: 'Rewards yield',
    value: formatPercentage(rewardsAPR)
  }

  const bottomItems: LineItemProps[] = [feeAprItem, rewardsAprItem]

  return (
    <div className="apr-hover">
      <LineItem {...totalItem} />
      <hr className="hr" />
      {bottomItems.map((item, index) => (
        <LineItem key={index} {...item} />
      ))}
    </div>
  )
}
