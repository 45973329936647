import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  LIQUID_MULTI_INFO,
  resetMultiStepState,
  selectActiveVault,
  selectBuiltFormTabs,
  selectFormInput,
  selectIsManage,
  selectIsReview,
  selectMultiStep,
  selectTab,
  setFormInputState,
  setFormOpenState,
  setIsReviewState,
  setMultiStepState
} from 'state/slices/ui/form'
import { DEPOSIT_TAB } from 'state/slices/ui/form'
import { TxInput, TxInputProps } from './TxInput'
import { formatPercentage, prettyTokenBal, scaleDown } from 'toolbox/format'
import LineItemList from 'components/common/LineItemList'
import CloseButton from 'components/common/CloseButton'
import { getWalletBalance, selectLoadedWalletBalances } from 'state/slices/user/walletBalances'
import { TxInputReview } from './TxInputReview'
import { Network, useWallet } from '@aptos-labs/wallet-adapter-react'
import { selectTxStatus, txStatusIdle } from 'state/slices/ui/transaction'
import Tabs from 'components/common/Tabs'
import { doTxBroker, tabToType, TxReqPayloadBroker } from 'state/thunks/doTxBroker'
import { ECHELON, LIQUIDSWAP, MOVEPOSITION } from 'state/types'
import { logAndToastError } from 'toolbox/toast'
import { useEnvironment } from 'App'
import { Aptos, AptosConfig, Ed25519PublicKey } from '@aptos-labs/ts-sdk'
import { doTxLiquid, TxReqPayloadLiquid } from 'state/thunks/doTxLiquid'
import { useEffect } from 'react'
import { selectBrokersAsUnified } from 'state/slices/app/brokers'
import { selectCoinTypesWithDecimals } from 'state/slices/app/vaults'
import { MultiStep, MultiStepProps } from 'components/common/MultiStep'
import { openWalletSignIn } from 'state/slices/ui/wallet'
import { MyPosition } from './MyPosition'
import { LPStake } from './LPStake'

export function TxForm({ isDetails }: { isDetails?: boolean }) {
  const dispatch = useAppDispatch()
  const { account, signAndSubmitTransaction, connected } = useWallet()
  const balances = useAppSelector(selectLoadedWalletBalances)
  const isManage = useAppSelector(selectIsManage)
  const formTab = useAppSelector(selectTab)
  const inputVal = useAppSelector(selectFormInput)
  const isReview = useAppSelector(selectIsReview)
  const brokers = useAppSelector(selectBrokersAsUnified)
  const isTxPending = useAppSelector(selectTxStatus) === 'pending'
  const tabs = useAppSelector(selectBuiltFormTabs)
  const { config } = useEnvironment()
  const coinTypeWithDecimals = useAppSelector(selectCoinTypesWithDecimals)
  const activeVault = useAppSelector(selectActiveVault)
  const myPosition = activeVault?.userPosition?.balance || 0
  const hasPosition = myPosition > 0
  const multiStep = useAppSelector(selectMultiStep)
  const isLiquidSwap = activeVault?.meta.investmentType === LIQUIDSWAP

  useEffect(() => {
    return () => {
      txStatusIdle()
    }
  }, [])
  if (!activeVault) return <div>Vault not found</div>

  const { decimals, assetName, coinType, assetPrice, supplyAPR, meta } = activeVault || {}

  const assetTicker = meta?.displayName || ''
  const logoURL = meta?.iconURL || ''
  const investmentType = meta?.investmentType || ''
  const lpTokenId = isLiquidSwap ? activeVault.ichiVault?.token_id_name || '' : ''
  const lpTokenBalance = getWalletBalance(balances, lpTokenId)
  const hasLpBalance = lpTokenBalance > 0

  const IS_DEPOSIT = formTab === DEPOSIT_TAB
  const title = IS_DEPOSIT
    ? isReview
      ? 'Review deposit details'
      : `Deposit to ${assetTicker} vault`
    : isReview
    ? 'Review withdrawal details'
    : `Withdraw from ${assetTicker} vault`
  const walletBalance = getWalletBalance(balances, coinType)

  const hasInput = Number(inputVal) > 0

  const nextPosition = IS_DEPOSIT ? myPosition + Number(inputVal) : myPosition - Number(inputVal)

  const lineItems = [
    {
      label: 'My position',
      value: `${prettyTokenBal(myPosition)}`,
      next: hasInput ? `${prettyTokenBal(nextPosition)}` : ''
    },
    {
      label: 'Annualized return',
      value: `${formatPercentage(supplyAPR)}`
    }
  ]

  const moreLineItems = [
    {
      label: 'My position',
      value: `${prettyTokenBal(myPosition)} ${assetTicker}`,
      next: hasInput ? `${prettyTokenBal(nextPosition)} ${assetTicker}` : ''
    },
    {
      label: 'Annualized return',
      value: `${formatPercentage(supplyAPR)}`
    }
  ]

  const inputProps: TxInputProps = {
    logo: logoURL,
    ticker: assetTicker,
    inputVal,
    setInputVal: setFormInputState,
    walletBalance,
    price: assetPrice,
    positionBalance: myPosition,
    isDeposit: IS_DEPOSIT
  }

  const overDepositBalance = Number(inputVal) > walletBalance
  const overWithdrawBalance = Number(inputVal) > myPosition

  const overBalance = IS_DEPOSIT ? overDepositBalance : overWithdrawBalance
  const overBalanceMessage = IS_DEPOSIT ? 'Exceeds wallet balance' : 'Exceeds deposited balance'

  const disabled = !hasInput || isTxPending || overBalance

  const action = IS_DEPOSIT ? 'Deposit and Stake' : 'Withdraw'

  const buttonText = !hasInput
    ? 'Enter amount'
    : isTxPending
    ? 'Transaction pending...'
    : overBalance
    ? overBalanceMessage
    : !isReview
    ? 'Review'
    : action

  const aptosConfig = new AptosConfig({
    network: config.network as Network,
    fullnode: config.aptosNodeUrl,
    faucet: config.aptosFaucetUrl
  })

  const aptos = new Aptos(aptosConfig)

  const txReqPayloadBroker: TxReqPayloadBroker = {
    formTab,
    address: account?.address as string,
    amount: Number(inputVal),
    vault: activeVault,
    signAndSub: signAndSubmitTransaction,
    envNetwork: config.network,
    API_URL: config.positionApiUrl,
    aptos: aptos,
    rootAddr: config.positionAptosRootAddress,
    echelonRootAddress: config.echelonContractAddress,
    nodeUrl: config.aptosNodeUrl,
    coinTypeWithDecimals
  }

  const txReqPayloadLiquid: TxReqPayloadLiquid = {
    formTab,
    address: account?.address as string,
    amount: Number(inputVal),
    vault: activeVault,
    signAndSub: signAndSubmitTransaction,
    aptos: aptos,
    envNetwork: config.network,
    API_URL: config.positionApiUrl,
    echelonRootAddress: config.echelonContractAddress,
    nodeUrl: config.aptosNodeUrl,
    publicKey: account?.publicKey
      ? new Ed25519PublicKey(account?.publicKey as string)
      : new Ed25519PublicKey('0x1234567890123456789012345678901234567890123456789012345678901234'),
    coinTypeWithDecimals,
    stakeAddress: config.stakeAddress
  }

  const onClick = () => {
    if (isReview) {
      switch (investmentType) {
        case MOVEPOSITION:
          dispatch(doTxBroker(txReqPayloadBroker))
          break
        case LIQUIDSWAP:
          dispatch(doTxLiquid(txReqPayloadLiquid))
          break
        default:
          logAndToastError('Invalid vault type', `The vault type ${investmentType} is invalid`)
      }
    } else {
      setIsReviewState(true)
    }
  }

  const showMultiStep = isLiquidSwap && isReview && activeVault.hasRewardsPools
  const multiProps: MultiStepProps = {
    steps: multiStep,
    info: LIQUID_MULTI_INFO
  }

  const showMyPosition = hasPosition && isDetails
  const showLPStake = hasLpBalance && activeVault.hasRewardsPools

  return (
    <>
      {showMyPosition && <MyPosition activeVault={activeVault} />}
      <div className="tx-form">
        {!isDetails && <CloseButton cb={() => setFormOpenState(false)} white />}
        <h3>{title}</h3>

        {isManage && !isReview && (
          <Tabs tabs={tabs} variant="basic" mods={'full-width'} disableTabSwitch={false} />
        )}

        {isReview ? (
          <TxInputReview {...inputProps} />
        ) : (
          <div className="tx-form-input-container">
            <TxInput {...inputProps} />
          </div>
        )}
        {isReview && <hr className="hr" />}
        <LineItemList items={lineItems} more={moreLineItems} />
        <div className="tx-form-buttons">
          {isReview && (
            <button
              className="btn rounded primary type-2 type-grey"
              onClick={() => setIsReviewState(false)}>
              Back
            </button>
          )}
          {connected ? (
            <button
              className="btn full-w rounded primary type-2"
              disabled={disabled}
              onClick={onClick}>
              {buttonText}
            </button>
          ) : (
            <button className="btn full-w rounded primary-dark type-2" onClick={openWalletSignIn}>
              Connect wallet
            </button>
          )}
        </div>
        {showMultiStep && <MultiStep {...multiProps} />}
        {showLPStake && <LPStake activeVault={activeVault} />}
      </div>
    </>
  )
}
