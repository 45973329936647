import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { fetchPoolInfo } from 'state/thunks/fetchPoolInfo'
import { PoolInfo } from 'state/types'

type State = Loadable<PoolInfo[]>
export const initialState = initLoadable<PoolInfo[]>([])

const poolInfoSlice = createSlice({
  name: 'poolInfo',
  initialState,
  reducers: {
    resetPoolInfo: (state: State) => {
      state.value = []
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPoolInfo.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })
    builder.addCase(fetchPoolInfo.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export const { resetPoolInfo } = poolInfoSlice.actions
export default poolInfoSlice.reducer

export const selectPoolInfo = (s: RootState) => s.app.poolInfo.value
export const selectPoolInfoBusy = (s: RootState) => s.app.poolInfo.status === 'busy'
export const selectPoolInfoLoaded = (s: RootState) => s.app.poolInfo.loadedOnce
