import usdc from 'assets/logos/usdc.svg'
import DoubleDataPoint, { DoubleDataPointProps } from 'components/common/DoubleDataPoint'
import { MetaToken, MetaTokenProps } from 'components/common/MetaToken'
import { prettyTokenBal } from 'toolbox/format'
import arrow from 'assets/icons/arrow-right.svg'
import whiteArrow from 'assets/icons/arrow-right-white.svg'
import { setActiveVaultState, setFormOpenState, setIsManageState } from 'state/slices/ui/form'
import { useState } from 'react'
import { setExploreVaultDetailsOpen } from 'state/slices/ui/explore'
import { VaultDataPoints } from './VaultDataPoints'

export interface VaultRowProps {
  vault?: any
  minimal?: boolean
}

export function VaultRowSkeleton({ vault, minimal }: VaultRowProps) {
  const [isHovered, setIsHovered] = useState(false)
  const onHover = () => setIsHovered(true)
  const onHoverOut = () => setIsHovered(false)

  const isUserLoaded = true
  const hasPosition = true
  const userPosition = 400000
  const walletBalance = 1000000

  const ticker = 'USDC'

  const tokenMeta: MetaTokenProps = {
    icon: usdc,
    ticker,
    pills: [
      { text: 'Label', colorClass: 'yellow' },
      { text: 'Label', colorClass: 'pink' },
      { text: 'Label', colorClass: 'blue' }
    ]
  }

  const doubleDataPoint: DoubleDataPointProps = {
    topLabel: `My position`,
    topValue: `${prettyTokenBal(userPosition)} ${ticker}`,
    bottomLabel: 'Wallet balance',
    bottomValue: `${prettyTokenBal(walletBalance)} ${ticker}`,
    showTop: isUserLoaded && hasPosition
  }

  const useCb = hasPosition
    ? () => {
        // setActiveVaultState(vault)
        setFormOpenState(true)
        setIsManageState(true)
      }
    : () => {
        // setActiveVaultState(vault)
        setFormOpenState(true)
        setIsManageState(false)
      }

  const action = hasPosition ? (
    <button onClick={useCb} className="btn primary">
      Manage liquidity
    </button>
  ) : (
    <button onClick={useCb} className="btn black">
      Add liquidity
    </button>
  )

  function openDetails() {
    // setActiveVaultState(vault)
    setExploreVaultDetailsOpen(true)
  }

  const minimalClass = minimal ? 'minimal' : ''
  const isHoverClass = isHovered ? 'hovered' : ''

  return (
    <div
      className={'vault-row-skeleton ' + minimalClass + ' ' + isHoverClass}
      onMouseEnter={onHover}
      onMouseLeave={onHoverOut}
      onClick={openDetails}>
      <div className="left">
        <div className="vault-meta">
          <div className="skeleton-circle" />
          <div className="skeleton-text" />
        </div>
        <div className="skel-data-con">
          <div className="skeleton-data" />
          <div className="divider" />
          <div className="skeleton-data" />
        </div>
      </div>
      {!minimal && (
        <div className="right">
          <div className="action-btns">
            <div className="skeleton-text" />
            <div className="skeleton-button" />
            {/* <div className="skeleton-button" /> */}
            {/* <div className="skeleton-button" /> */}
          </div>
        </div>
      )}
    </div>
  )
}
