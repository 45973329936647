import { Aptos, AptosConfig, Network } from '@aptos-labs/ts-sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EnvironmentConfig } from 'state/envConfigs'
import { PoolInfo } from 'state/types'

export interface FetchStakedBalancesPayload {
  config: EnvironmentConfig
  userAddress: string
  poolInfo: PoolInfo[]
}

export const fetchStakedBalances = createAsyncThunk<any, FetchStakedBalancesPayload>(
  'stakedBalances/fetch',
  async (payload: FetchStakedBalancesPayload) => {
    const { config, userAddress, poolInfo } = payload
    if (!config || !config.stakeAddress || !userAddress || poolInfo.length === 0) {
      console.error('Invalid payload for fetching staked rewards')
      return {}
    }
    const aptosConfig = new AptosConfig({
      network: config.network as Network,
      fullnode: config.aptosNodeUrl,
      faucet: config.aptosFaucetUrl
    })

    if (!aptosConfig) {
      console.error('Invalid aptos config')
      return {}
    }

    const aptos = new Aptos(aptosConfig)

    if (!aptos) {
      console.error('Invalid aptos client')
      return {}
    }

    let stakedBalances: { [key: string]: number } = {}

    try {
      await Promise.all(
        poolInfo.map(async (poolInfo) => {
          const balance = await aptos.view({
            payload: {
              function: `${config.stakeAddress}::multi_rewards::get_user_staked_balance`,
              typeArguments: [],
              functionArguments: [userAddress, poolInfo.stakingToken]
            }
          })

          console.log('STAKED BALANCE RES', balance)
          stakedBalances = {
            ...stakedBalances,
            [poolInfo.coinType]: Number(balance[0])
          }
        })
      )
      return stakedBalances
    } catch (e: any) {
      console.error('Error fetching staked rewards:', e)
      return {}
    }
  }
)
