import { useEffect, useMemo, useRef } from 'react'
import { Toaster } from 'react-hot-toast'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useLocation } from 'react-router-dom'
import { fetchVaults } from 'state/thunks/fetchVaults'
import { Network, useWallet } from '@aptos-labs/wallet-adapter-react'
import TopNav from './TopNav'
import { Routing } from './Routing'
import { AppModals } from '../components/modals/AppModals'
import { fetchAppData, fetchUserData } from 'state/fetch'
import { useEnvironment } from 'App'
import { AptosConfig } from '@aptos-labs/ts-sdk'
import { Aptos } from '@aptos-labs/ts-sdk'
import { ThemeProvider } from 'state/ThemeContext'
import { selectCanopyMetaLoadedOnce, selectLoadedCanopyMeta } from 'state/slices/app/canopyMeta'
import { fetchCanopyMeta } from 'state/thunks/fetchCanopyMeta'
import { isEmptyOrNil } from 'toolbox/account'
import {
  getVaultByCoinType,
  selectAllActivePools,
  selectCoinTypesWithDecimals,
  selectUnifiedVaults,
  selectVaultCoinTypes
} from 'state/slices/app/vaults'
import { selectWalletBalancesBusy } from 'state/slices/user/walletBalances'
import { selectPortfolioBusy } from 'state/slices/user/portfolio'
import { fetchStakedBalances } from 'state/thunks/fetchStakedBalances'
import { fetchPoolInfo } from 'state/thunks/fetchPoolInfo'
import { selectPoolInfo, selectPoolInfoLoaded } from 'state/slices/app/poolInfo'
import { fetchRewardsBalances } from 'state/thunks/fetchRewardsBalances'
import { selectTxStatus } from 'state/slices/ui/transaction'
import { selectActiveVault, setActiveVaultState } from 'state/slices/ui/form'

function InnerApp() {
  const { connected, account, signAndSubmitTransaction } = useWallet()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { config } = useEnvironment()
  const canopyMeta = useAppSelector(selectLoadedCanopyMeta)
  const canopyMetaLoadedOnce = useAppSelector(selectCanopyMetaLoadedOnce)
  const coinTypeWithDecimals = useAppSelector(selectCoinTypesWithDecimals)
  const isWalletBalancesLoading = useAppSelector(selectWalletBalancesBusy)
  const isPortfolioLoading = useAppSelector(selectPortfolioBusy)
  const isUserDataFetching = isWalletBalancesLoading || isPortfolioLoading
  const vaultCoinTypes = useAppSelector(selectVaultCoinTypes)
  const vaults = useAppSelector(selectUnifiedVaults)
  const poolInfo = useAppSelector(selectPoolInfo)
  const poolInfoLoadedOnce = useAppSelector(selectPoolInfoLoaded)
  const txStatus = useAppSelector(selectTxStatus)
  const activeVault = useAppSelector(selectActiveVault)
  const allActivePools = useAppSelector(selectAllActivePools)
  const aptosConfig = useMemo(
    () =>
      new AptosConfig({
        network: config.network as Network,
        fullnode: config.aptosNodeUrl,
        faucet: config.aptosFaucetUrl
      }),
    [config.network, config.aptosNodeUrl, config.aptosFaucetUrl]
  )

  const aptos = useMemo(() => new Aptos(aptosConfig), [aptosConfig])

  const splash = pathname === '/'

  //load initial system meta
  useEffect(() => {
    dispatch(fetchCanopyMeta())
  }, [dispatch, config.aptosNodeUrl])

  // Memoize the fetch functions to prevent recreation on every render
  const fetchAppDataMemo = useMemo(() => {
    return () =>
      fetchAppData({
        url: config.positionApiUrl,
        aptos,
        echelonAddress: config.echelonContractAddress,
        nodeUrl: config.aptosNodeUrl
      })
  }, [config.positionApiUrl, aptos, config.echelonContractAddress, config.aptosNodeUrl])

  // Update the useEffect to use memoized function
  useEffect(() => {
    if (canopyMetaLoadedOnce && !isEmptyOrNil(canopyMeta)) {
      fetchAppDataMemo()
      dispatch(fetchVaults({ nodeUrl: config.aptosNodeUrl, aptos, canopyMeta }))
    }
  }, [dispatch, fetchAppDataMemo, config.aptosNodeUrl, canopyMeta, canopyMetaLoadedOnce, aptos])

  useEffect(() => {
    if (Object.keys(allActivePools).length > 0) {
      dispatch(fetchPoolInfo({ config, typeToPools: allActivePools }))
    }
  }, [dispatch, config, allActivePools])

  useEffect(() => {
    if (connected && account?.address && coinTypeWithDecimals.length > 0) {
      // Add a flag to prevent multiple fetches
      fetchUserData(
        account.address,
        coinTypeWithDecimals,
        config.positionApiUrl,
        aptos,
        config.echelonContractAddress
      )
    }
  }, [
    connected,
    account?.address,
    coinTypeWithDecimals.length // Only depend on the length
  ])

  const hasInitiallyFetchedStaked = useRef(false)

  useEffect(() => {
    if (
      connected &&
      account?.address &&
      poolInfo.length > 0 &&
      !isUserDataFetching &&
      !hasInitiallyFetchedStaked.current
    ) {
      hasInitiallyFetchedStaked.current = true
      dispatch(
        fetchStakedBalances({
          config,
          userAddress: account.address,
          poolInfo
        })
      )
    }
  }, [connected, account?.address, poolInfo.length, isUserDataFetching])

  useEffect(() => {
    if (connected && account?.address && poolInfo.length > 0 && !isUserDataFetching) {
      dispatch(fetchRewardsBalances({ config, userAddress: account.address, poolInfo }))
    }
  }, [connected, account?.address, poolInfo, isUserDataFetching, dispatch, config])

  const prevStatusRef = useRef(txStatus)

  useEffect(() => {
    if (prevStatusRef.current === 'pending' && txStatus === 'idle' && activeVault) {
      // Refresh the broker data
      const updatedVault = getVaultByCoinType(activeVault.coinType, vaults)
      if (updatedVault) {
        setActiveVaultState(updatedVault)
      }
    }

    prevStatusRef.current = txStatus
  }, [txStatus, activeVault, vaults])

  return (
    <>
      <ThemeProvider>
        {/* <Context.Provider value={{ context, setContext }}> */}
        <div id="App">
          <TopNav />
          <section className="section-wrapper">
            <div className="section">
              <Routing />
            </div>
          </section>
          <section className="section-wrapper">
            <div className="section no-height">{/* <Footer /> */}</div>
          </section>
        </div>
        <AppModals />

        <Toaster position="bottom-left" />
        {/* </Context.Provider> */}
        {!!splash && <div className="splash-bg" />}
        <div className="gradient-background">
          <div className="gradient-background-inner" />
        </div>
      </ThemeProvider>
    </>
  )
}

export default InnerApp
