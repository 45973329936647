import { createAsyncThunk } from '@reduxjs/toolkit'
import { logToastThrow, toastLoad, toastSuccess } from 'toolbox/toast'
import { SignAndSubmitTransactionCallback, UnifiedVault } from 'state/types'
import { postTransactionRefresh } from 'state/fetch'
import {
  DEPOSIT_TAB,
  FormTab,
  LIQUID_MULTI_STEPS,
  MULTI_COMPLETED,
  MULTI_PENDING,
  resetMultiStepState,
  setFormOpenState,
  TxMultiStep,
  updateMultiStepState
} from 'state/slices/ui/form'
import { scaleUp } from 'toolbox/format'
import { setTxSuccessState, txStatusIdle, txStatusPending } from 'state/slices/ui/transaction'
import { Aptos, Hex, Network, PublicKey } from '@aptos-labs/ts-sdk'
import {
  normalizeHexAddress,
  signAndSubmitterBasic,
  updateSuccessStateAndWait
} from 'toolbox/aptos-util'
import { LIQUID_APT } from './fetchVaults'
import { CoinTypeWithDecimals } from 'state/slices/app/vaults'
import { InputTransactionData } from '@aptos-labs/wallet-adapter-react'
import { AptosTx } from 'toolbox/aptosTx'

export interface TxReqPayloadLiquid {
  formTab: FormTab
  amount: number
  vault: UnifiedVault
  address: string
  signAndSub: SignAndSubmitTransactionCallback
  aptos: Aptos
  envNetwork: Network
  API_URL: string
  echelonRootAddress: string
  nodeUrl: string
  publicKey: PublicKey
  coinTypeWithDecimals: CoinTypeWithDecimals[]
  stakeAddress: string
}

//TODO: read root address
const ICHI_ROOT = '0x3356bce7af6eb2c332a6e944eb54c6383fec0d099af62e1618a29c7ea8e6cbc7'
//TODO: real slippage
const SLIPPAGE_HARD = 0.01

export const doTxLiquid = createAsyncThunk<any, TxReqPayloadLiquid>(
  'market/doTxLiquid',
  async (payload: TxReqPayloadLiquid): Promise<any> => {
    try {
      toastLoad('Building transaction...')
      txStatusPending({ type: payload.formTab, info: 'Signing transaction...' })
      updateMultiStepState(0, MULTI_PENDING)
      console.log('VAULT IN DO TX LIQUID', payload.vault)

      const { vault, envNetwork, aptos } = payload

      if (!vault || !envNetwork || !aptos) {
        logToastThrow(
          'Missing required system configuration',
          'Check the console for more information'
        )
      }

      const aptosTx = new AptosTx(ICHI_ROOT, payload.stakeAddress)

      if (!payload.nodeUrl || !envNetwork || !aptos) {
        console.log('Env network: ', envNetwork)
        console.log('aptosSDK: ', aptos)
        logToastThrow(
          'Missing required system configuration',
          'Check the console for more information'
        )
      }

      const { amount, address, API_URL } = payload
      if (!vault || !amount || !address) {
        logToastThrow(
          'Missing arguments',
          'The transaction request is missing required arguments, please try again'
        )
      }

      const { coinType, meta, decimals, ichiVault } = vault
      const { displayName } = meta

      const isDepositTab = payload.formTab === DEPOSIT_TAB

      const amountRaw = Math.floor(Number(scaleUp(amount, decimals)))
      const BIN_STEP =
        '0x19b400ef28270cdd00ff826412a13b2e7d82a8a0762c46bed34a6e8d52f0275a::bin_steps::X20'

      let prices: any[] = []

      const isXDeposit = ichiVault?.is_x_deposit
      const xCoin = ichiVault?.coin_x_address
      const yCoin = ichiVault?.coin_y_address

      if (!xCoin || !yCoin) {
        console.error('Missing X || Ycoin type', xCoin, yCoin)
        logToastThrow('Missing X || Ycoin type', 'Check the console for more information')
      }

      if (isDepositTab) {
        try {
          toastLoad('Fetching latest prices...')
          const getDepositPrices = async (vaultAddr: string) => {
            console.log('X!', xCoin)
            console.log('Y!', yCoin)
            console.log('BIN!', BIN_STEP)
            const data = await aptos.view({
              payload: {
                function: `${ICHI_ROOT}::vault::get_deposit_prices_bin_id`,
                typeArguments: [xCoin as string, yCoin as string, BIN_STEP],
                functionArguments: [vaultAddr]
              }
            })

            console.log('Deposit prices:', data)
            return data
          }
          prices = await getDepositPrices(vault.meta.networkAddress)
          console.log('prices before catched', prices)
        } catch (e: any) {
          console.error(e)
          logToastThrow('Error fetching deposit prices', e.message)
        }
      }

      const activeBinDesired = Number(prices[0])
      const activeBinSlippage = Math.floor(activeBinDesired * (1 - SLIPPAGE_HARD))
      let finalMinShares = BigInt(1)
      let options = {
        gasUnitPrice: 100,
        maxGasAmount: 100000000
      }

      if (isDepositTab) {
        try {
          toastLoad('Simulating transaction...')
          const { minShares, max_gas_amount, gas_unit_price } =
            await simulateDepositAndGetMinShares({
              aptos,
              address,
              publicKey: payload.publicKey,
              vault,
              coinType,
              amountRaw,
              activeBinDesired,
              activeBinSlippage,
              slippage: SLIPPAGE_HARD
            })
          finalMinShares = minShares
          options.gasUnitPrice = Number(gas_unit_price)
          options.maxGasAmount = Number(max_gas_amount)
        } catch (e: any) {
          console.error(e)
          logToastThrow('Error simulating transaction', e.message)
        }
      }

      const amountX = isXDeposit ? amountRaw : 0
      const amountY = isXDeposit ? 0 : amountRaw

      const depositTxPayload = aptosTx.depositIx({
        coinTypeX: xCoin as string,
        coinTypeY: yCoin as string,
        amountX: BigInt(amountX),
        amountY: BigInt(amountY),
        sender: address,
        binStep: BIN_STEP,
        vault: vault.meta.networkAddress,
        activeBinDesired,
        activeBinSlippage,
        minShares: finalMinShares
      })

      const withdrawTxPayload = aptosTx.withdrawIx({
        coinTypeX: xCoin as string,
        coinTypeY: yCoin as string,
        amount: BigInt(amountRaw),
        sender: address,
        binStep: BIN_STEP,
        vault: vault.meta.networkAddress
      })

      const txPayload = isDepositTab ? depositTxPayload : withdrawTxPayload

      let result: any

      //step one
      try {
        toastLoad('Signing transaction...')
        console.log('start deposit')
        const txResult = await processTransaction({
          signAndSub: payload.signAndSub,
          txPayload,
          address,
          coinTypeWithDecimals: payload.coinTypeWithDecimals,
          API_URL,
          aptos,
          echelonRootAddress: payload.echelonRootAddress,
          nodeUrl: payload.nodeUrl,
          formTab: payload.formTab,
          options: isDepositTab ? options : undefined
        })
        result = txResult
      } catch (e: any) {
        console.error(e)
        logToastThrow('Transaction failed', e.message)
      }

      if (!payload.vault.hasRewardsPools) {
        postTransactionRefresh(
          address,
          payload.coinTypeWithDecimals,
          API_URL,
          aptos,
          payload.echelonRootAddress,
          payload.nodeUrl
        )
        wrapUp()
        return
      }

      //steps 2 / 3
      try {
        updateMultiStepState(0, MULTI_COMPLETED)
        updateMultiStepState(1, MULTI_PENDING)
        toastLoad('Staking and subscribing...')
        const userTokenDepositEvent = result.events?.find(
          (e: any) =>
            e.type.includes('::token::DepositEvent') &&
            normalizeHexAddress(e.guid.account_address) === address
        )
        const lpTokenAmountRaw = userTokenDepositEvent?.data?.amount
        const propertyVersion = userTokenDepositEvent?.data?.id?.property_version
        const collection = userTokenDepositEvent?.data?.id?.token_data_id?.collection
        const name = userTokenDepositEvent?.data?.id?.token_data_id?.name
        const creator = userTokenDepositEvent?.data?.id?.token_data_id?.creator

        const pools = vault.meta.rewardPools || []
        const hasPools = pools.length > 0

        let unsubscribedPools: string[] = []

        if (hasPools) {
          try {
            const poolSubscriptionStatus = await Promise.all(
              pools.map(async (pool) => {
                const isSubscribed = await aptos.view({
                  payload: {
                    function: `${payload.stakeAddress}::multi_rewards::is_user_subscribed`,
                    typeArguments: [],
                    functionArguments: [address, pool]
                  }
                })
                return { pool, isSubscribed: isSubscribed[0] }
              })
            )

            unsubscribedPools = poolSubscriptionStatus
              .filter(({ isSubscribed }) => !isSubscribed)
              .map(({ pool }) => pool)

            console.log('Pool subscription status:', poolSubscriptionStatus)
            console.log('Unsubscribed pools:', unsubscribedPools)
          } catch (e: any) {
            console.error(e)
            logToastThrow('Error fetching pool subscription status', e.message)
          }
        }

        const hasUnsubscribedPools = unsubscribedPools.length > 0

        const stakeAndSubscribeTxPayload = aptosTx.stakeAndSubscribeIx({
          sender: address,
          pools: hasUnsubscribedPools ? unsubscribedPools : [],
          amount: lpTokenAmountRaw,
          creator,
          collection,
          name,
          propertyVersion
        })

        const stakeIxPayload = aptosTx.stakeIx({
          sender: address,
          amount: lpTokenAmountRaw,
          creator,
          collection,
          name,
          propertyVersion
        })

        const usePayload = hasUnsubscribedPools ? stakeAndSubscribeTxPayload : stakeIxPayload
        console.log('USE PAYLOAD', usePayload)

        const hash = await signAndSubmitterBasic({
          signAndSub: payload.signAndSub,
          ixData: usePayload
        })
        console.log('Stake TX HASH:', hash)

        const stakeAndSubscribeResult = await updateSuccessStateAndWait({
          hash: hash.hash,
          aptos,
          action: payload.formTab
        })

        console.log('Stake and subscribe result:', stakeAndSubscribeResult)
        updateMultiStepState(1, MULTI_COMPLETED)
      } catch (e: any) {
        console.error(e)
        logToastThrow('Transaction failed', e.message)
      }

      toastSuccess('Transaction completed!')

      postTransactionRefresh(
        address,
        payload.coinTypeWithDecimals,
        API_URL,
        aptos,
        payload.echelonRootAddress,
        payload.nodeUrl
      )
      wrapUp()
    } catch (e: any) {
      console.error(e)
      txStatusIdle()
      logToastThrow('Transaction failed', e.message)
    }
  }
)

export async function wrapUp() {
  txStatusIdle()
  setFormOpenState(false)
  resetMultiStepState()
}

export async function processTransaction({
  signAndSub,
  txPayload,
  address,
  coinTypeWithDecimals,
  API_URL,
  aptos,
  echelonRootAddress,
  nodeUrl,
  formTab,
  options
}: {
  signAndSub: SignAndSubmitTransactionCallback
  txPayload: InputTransactionData
  address: string
  coinTypeWithDecimals: CoinTypeWithDecimals[]
  API_URL: string
  aptos: Aptos
  echelonRootAddress: string
  nodeUrl: string
  formTab: FormTab
  options?: { gasUnitPrice: number; maxGasAmount: number }
}) {
  if (options) {
    const hash = await signAndSubmitterBasic({
      signAndSub,
      ixData: txPayload,
      options
    })
    console.log('TX HASH:', hash)
    const result = await updateSuccessStateAndWait({
      hash: hash.hash,
      aptos,
      action: formTab
    })
    postTransactionRefresh(
      address,
      coinTypeWithDecimals,
      API_URL,
      aptos,
      echelonRootAddress,
      nodeUrl
    )

    return result
  } else {
    const hash = await signAndSubmitterBasic({
      signAndSub,
      ixData: txPayload
    })
    console.log('TX HASH:', hash)
    const result = await updateSuccessStateAndWait({
      hash: hash.hash,
      aptos,
      action: formTab
    })
    postTransactionRefresh(
      address,
      coinTypeWithDecimals,
      API_URL,
      aptos,
      echelonRootAddress,
      nodeUrl
    )

    return result
  }
}

export async function simulateDepositAndGetMinShares({
  aptos,
  address,
  publicKey,
  vault,
  coinType,
  amountRaw,
  activeBinDesired,
  activeBinSlippage,
  slippage
}: {
  aptos: Aptos
  address: string
  publicKey: PublicKey
  vault: UnifiedVault
  coinType: string
  amountRaw: number
  activeBinDesired: number
  activeBinSlippage: number
  slippage: number
}): Promise<{ minShares: bigint; max_gas_amount: string; gas_unit_price: string }> {
  //TODO: get from indexer
  const BIN_STEP =
    '0x19b400ef28270cdd00ff826412a13b2e7d82a8a0762c46bed34a6e8d52f0275a::bin_steps::X20'

  const simTX = await aptos.transaction.build.simple({
    sender: address,
    data: {
      function: `${ICHI_ROOT}::entry::deposit`,
      typeArguments: [coinType, LIQUID_APT, BIN_STEP],
      functionArguments: [
        vault.meta.networkAddress,
        activeBinDesired,
        activeBinSlippage,
        BigInt(amountRaw),
        BigInt(0),
        BigInt(1)
      ]
    }
  })

  const simulated = await aptos.transaction.simulate.simple({
    signerPublicKey: publicKey,
    transaction: simTX
  })
  console.log('simulated', simulated)

  const depositEvent = simulated[0].events?.find((e: any) => e.type.includes('::vault::Deposit'))

  const min = Math.floor(Number(depositEvent?.data?.shares_minted) * (1 - slippage))

  const minShares = min > 0 ? BigInt(min) : BigInt(1)
  const max_gas_amount = simulated[0].max_gas_amount
  const gas_unit_price = simulated[0].gas_unit_price

  return { minShares, max_gas_amount, gas_unit_price }
}
