import DataPoint, { DataPointProps } from 'components/common/DataPoint'
import { UnifiedVault } from 'state/types'
import { formatCurrency, prettyTokenBalWithK } from 'toolbox/format'
import { useState } from 'react'
import { APRHover } from './APRHover'

export function VaultDataPoints({
  vault,
  expanded
}: {
  vault: UnifiedVault | null
  expanded?: boolean
}) {
  const [isHovered, setIsHovered] = useState(false)

  if (!vault) return null
  const { supplyAPR, tvlUSD } = vault
  const isSupplyAPRPositive = supplyAPR > 0
  const price = vault.assetPrice
  const myPosition = vault.userPosition?.balance || 0

  const dataLeft: DataPointProps[] = [
    {
      label: 'Annualized return',
      subValue: {
        subVal: supplyAPR,
        isPostive: isSupplyAPRPositive
      }
    },
    {
      label: 'TVL',
      value: formatCurrency(tvlUSD),
      tooltip: 'Total Value Locked'
    }
  ]

  const dataRight: DataPointProps[] = [
    {
      label: 'Price',
      value: formatCurrency(price),
      tooltip: 'Price'
    },
    {
      label: 'My Position',
      value: prettyTokenBalWithK(myPosition),
      tooltip: 'My Position'
    }
  ]

  return (
    <div className="data-points">
      <div
        className="apr-wrapper"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <DataPoint {...dataLeft[0]} />
      </div>
      {isHovered && <APRHover vault={vault} />}
      <DataPoint {...dataLeft[1]} />

      {expanded && (
        <>
          <div className="divider" />
          {dataRight.map((dataPoint, index) => (
            <DataPoint key={index.toString() + 'right'} {...dataPoint} />
          ))}
        </>
      )}
    </div>
  )
}
