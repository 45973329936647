import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchBrokers = createAsyncThunk(
  'brokers/fetchAll',
  async ({ url }: { url: string }): Promise<any> => {
    if (!url) {
      throw new Error('Position API URL not set')
    }
    try {
      const brokersResponse = await fetch(`${url}/brokers`)
      const brokers = await brokersResponse.json()
      console.log('Brokers: ', brokers)
      return brokers
    } catch (error) {
      console.error('Error fetching brokers: ', error)
      return []
    }
  }
)
