import chev from 'assets/icons/down-chev-secondary.svg'
import chevWhite from 'assets/icons/down-chev-white.svg'

export interface TokenDropButtonProps {
  ticker: string
  callback: () => void
  logo: string
}

export function TokenDropButton({ ticker, callback, logo }: TokenDropButtonProps) {
  const hasTicker = ticker && ticker !== ''
  const color = hasTicker ? 'secondary' : 'primary'
  const chevronDown = color === 'primary' ? chevWhite : chev
  const text = hasTicker ? ticker : 'Select any token'
  const noTickerClass = !hasTicker ? 'no-ticker' : ''
  return (
    <button className={` token-pill ${noTickerClass}`} onClick={callback}>
      {logo && <img src={logo} alt="logo" />}
      <p className="token-pill-name">{text}</p>
      <img src={chevronDown} alt="chevron" className="chevron" />
    </button>
  )
}
