import { useWallet, WalletName } from '@aptos-labs/wallet-adapter-react'
import connectedIcon from 'assets/icons/connected.svg'
import { useEffect, useState } from 'react'
import { logAndToastError } from 'toolbox/toast'
import { closeWallet } from 'state/slices/ui/wallet'
import { isEmptyOrNil } from 'toolbox/account'
import InfoBox, { InfoBoxProps } from 'components/common/InfoBox'
import { WalletInfo } from '@aptos-labs/wallet-adapter-core'

function WalletsList() {
  const { wallet, wallets, connect, connected } = useWallet()
  const w = useWallet()
  const [isConnecting, setIsConnecting] = useState('')

  const connectWallet = async (name: WalletName) => {
    setIsConnecting(name)
    try {
      await connect(name)
    } catch (e) {
      setIsConnecting('false')
      logAndToastError('Wallet', 'Wallet Connection Failed')
    }
  }

  useEffect(() => {
    if (connected) {
      setIsConnecting('')
    }
  }, [connected])

  const emptyInfoBox: InfoBoxProps = {
    color: 'blue',
    showBox: isEmptyOrNil(wallets),
    text: 'No wallets found',
    fullWidth: true
  }

  if (isEmptyOrNil(wallets)) {
    return (
      <div className="wallets-list">
        <InfoBox {...emptyInfoBox} />
      </div>
    )
  }

  function renderWalletContainer(wallet: WalletInfo, additionalText = '', noStyle = false) {
    if (wallet.name === 'Continue with Google') {
      return null
    }
    return (
      <div
        className={'wallet-container'}
        onClick={() => connectWallet(wallet.name)}
        key={wallet.name}>
        <img src={wallet.icon} alt={wallet.name} className="wallet-icon" />
        <div className="wallet-info">
          <h3>{wallet.name}</h3>
          {!!additionalText && <h4>({additionalText})</h4>}
        </div>
      </div>
    )
  }

  function renderConnectedWalletContainer(wallet: WalletInfo) {
    return (
      <div className="wallet-container connected">
        <div className="lined">
          <img src={wallet.icon} alt={'Wallet Connected'} className="connected-icon" />
          <h3>Connected</h3>
        </div>
        <img src={connectedIcon} alt={'Connected'} className="connect-icon" />
      </div>
    )
  }

  const uniqueByProperty = <T,>(array: T[], property: keyof T): T[] => {
    const seen = new Set()
    return array.filter((item) => {
      const key = item[property]
      if (seen.has(key)) {
        return false
      } else {
        seen.add(key)
        return true
      }
    })
  }

  const filteredWallets = uniqueByProperty([...(wallets ?? [])], 'name')

  const pageOneDisplay = filteredWallets.map((w, i) => {
    const isConnectingWallet = isConnecting === w.name

    if (w.readyState !== 'Installed') {
      const isMSafe = w.name === 'MSafe'
      const installationText = isMSafe ? 'Go to MSafe' : 'Install Extension'
      return (
        <a href={w.url} target="_blank" rel="noopener noreferrer" key={i}>
          {renderWalletContainer(w, installationText, true)}
        </a>
      )
    } else {
      const connectingText = isConnectingWallet ? 'Connecting...' : 'Detected'
      return renderWalletContainer(w, connectingText)
    }
  })

  return (
    <div className="wallets-list">
      {connected ? (
        <>
          {wallet && renderConnectedWalletContainer(wallet)}
          <button className="btn secondary full-w" onClick={closeWallet}>
            Close
          </button>
        </>
      ) : (
        <>{pageOneDisplay}</>
      )}
    </div>
  )
}

export default WalletsList
