import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { store } from 'state/store'
import InnerApp from 'layout/InnerApp'
import WalletProvider from 'layout/WalletProvider'
import { aptosDevConfig, EnvironmentConfig } from 'state/envConfigs'
import ErrorBoundary from 'layout/ErrorBoundary'

interface EnvironmentContextType {
  config: EnvironmentConfig
  updateConfig: (newConfig: Partial<EnvironmentConfig>) => void
}

const EnvironmentContext = createContext<EnvironmentContextType | undefined>(undefined)

export function EnvironmentProvider({ children }: { children: ReactNode }) {
  // Initialize state from localStorage, falling back to aptosDevConfig if nothing is stored
  const [config, setConfig] = useState<EnvironmentConfig>(() => {
    const savedConfig = localStorage.getItem('environmentConfig')
    return savedConfig ? JSON.parse(savedConfig) : aptosDevConfig
  })

  const updateConfig = (newConfig: Partial<EnvironmentConfig>) => {
    setConfig((prev) => {
      const updatedConfig = {
        ...prev,
        ...newConfig
      }
      // Save to localStorage whenever config is updated
      localStorage.setItem('environmentConfig', JSON.stringify(updatedConfig))
      return updatedConfig
    })
  }

  return (
    <EnvironmentContext.Provider value={{ config, updateConfig }}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export function useEnvironment() {
  const context = useContext(EnvironmentContext)
  if (context === undefined) {
    throw new Error('useEnvironment must be used within an EnvironmentProvider')
  }
  return context
}

function App() {
  return (
    <ErrorBoundary>
      <EnvironmentProvider>
        <Provider store={store}>
          {/* <AdapterProvider> */}
          <WalletProvider>
            <div className="app">
              <InnerApp />
            </div>
          </WalletProvider>
          {/* </AdapterProvider> */}
        </Provider>
      </EnvironmentProvider>
    </ErrorBoundary>
  )
}

export default App
