import { UnifiedVault } from 'state/types'
import { formatCurrency, prettyTokenBal } from 'toolbox/format'

export function MyPosition({ activeVault }: { activeVault: UnifiedVault }) {
  const userPositionBalance = activeVault?.userPosition?.balance || 0
  const userPositionBalanceUSD = activeVault?.userPosition?.value || 0
  const displayName = activeVault?.meta.displayName

  return (
    <div className="my-position">
      <p className="my-position-title">My position</p>
      <div className="my-position-balance">
        <p className="dark">
          {prettyTokenBal(userPositionBalance)}&nbsp;{displayName}
        </p>
        <p className="light">{formatCurrency(userPositionBalanceUSD)}</p>
      </div>
    </div>
  )
}
