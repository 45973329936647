import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable } from 'state'
import { RootState } from 'state/store'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchBrokerHistory } from 'state/thunks/fetchBrokerHistory'
import { selectActiveVault } from '../ui/form'
import { RateHistory } from 'components/charts/BarLineRateHistory'

export interface BrokerHistory {
  id: number
  broker_id: string
  network_address: string
  network: string
  available_liquidity: string
  total_borrowed: string
  scaled_available_liquidity: string
  scaled_total_borrowed: string
  interest_rate: string
  max_deposit: string
  max_borrow: string
  utilization: string
  deposite_note_exchange_rate: string
  loan_note_exchange_rate: string
  created_at: number
}

const initialState = initLoadable<BrokerHistory[]>([])

export const brokerHistories = createSlice({
  name: 'brokerHistories',
  initialState,
  reducers: {
    clearBrokerHistory: (state) => {
      state.value = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrokerHistory.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchBrokerHistory.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export default brokerHistories.reducer

export const { clearBrokerHistory } = brokerHistories.actions

export const selectBrokerHistory = (s: RootState) => s.app.brokerHistory.value
export const selectBrokerHistoryBusy = (s: RootState) => s.app.brokerHistory.status === 'busy'
export const selectBrokerHistoryLoaded = (s: RootState) => s.app.brokerHistory.loadedOnce

export const selectTVLHistory = createSelector(
  [selectBrokerHistory, selectActiveVault],
  (brokerHistory, vault) => {
    if (isEmptyOrNil(brokerHistory) || isEmptyOrNil(vault) || !vault) {
      return []
    }

    const numberOfDays = 30

    const totals: RateHistory[] = []
    for (let i = 0; i < numberOfDays; i++) {
      const dailyUtilization = getDailyUtilizationsGroupedByDay(brokerHistory, i - 1)
      const dayKeys = Object.keys(dailyUtilization)
      dayKeys.forEach((dk) => {
        const dailyHistory = dailyUtilization[Number(dk)]

        const totalAvailableLiquidity = dailyHistory.reduce(
          (acc, val) => acc + Number(val.scaled_available_liquidity),
          0
        )

        const totalBorrow = dailyHistory.reduce(
          (acc, val) => acc + Number(val.scaled_total_borrowed),
          0
        )

        const tvl = (totalAvailableLiquidity + totalBorrow) * vault.assetPrice

        const length = dailyHistory.length

        const apr = dailyHistory.reduce((acc, val) => acc + Number(val.interest_rate), 0)

        totals.push({
          timestamp: Number(dk),
          tvl: tvl / length,
          apr: apr / length
        })
      })
    }
    return totals.reverse()
  }
)

export const getDailyUtilizationsGroupedByDay = (
  utilizationHistories: BrokerHistory[],
  dayOffset: number
): { [endOfDay: number]: BrokerHistory[] } => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  //offset by i to get single days
  const endOfDay = now.getTime() - dayOffset * 86400000
  const startOfDay = endOfDay - 86400000

  //filter for single day windows
  const filteredHistories = utilizationHistories.filter((history) => {
    const timestamp = history.created_at * 1000
    return timestamp > startOfDay && timestamp < endOfDay
  })

  //return result object keyed by end of day timestamp
  const result = {
    [endOfDay]: filteredHistories
  }

  return result
}
