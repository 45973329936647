import React, { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
  children: ReactNode
  fallback?: ReactNode
}

interface State {
  hasError: boolean
  error?: Error
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  componentDidMount() {
    // Add global error handlers
    window.addEventListener('error', this.handleError)
    window.addEventListener('unhandledrejection', this.handlePromiseError)
  }

  componentWillUnmount() {
    // Clean up error handlers
    window.removeEventListener('error', this.handleError)
    window.removeEventListener('unhandledrejection', this.handlePromiseError)
  }

  private handleError = (event: ErrorEvent) => {
    event.preventDefault()
    this.setState({
      hasError: true,
      error: event.error || new Error(event.message)
    })
  }

  private handlePromiseError = (event: PromiseRejectionEvent) => {
    event.preventDefault()
    this.setState({
      hasError: true,
      error: event.reason instanceof Error ? event.reason : new Error(String(event.reason))
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <div className="error-fallback">
            <h1>Something went wrong</h1>
            <p>{this.state.error?.message}</p>
            <button onClick={() => window.location.reload()}>Refresh Page</button>
          </div>
        )
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
