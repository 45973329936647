import { Aptos, AptosConfig, Network } from '@aptos-labs/ts-sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EnvironmentConfig } from 'state/envConfigs'
import { PoolInfo } from 'state/types'

export interface FetchRewardsBalancesPayload {
  config: EnvironmentConfig
  userAddress: string
  poolInfo: PoolInfo[]
}

export const fetchRewardsBalances = createAsyncThunk<any, FetchRewardsBalancesPayload>(
  'rewardsBalances/fetch',
  async (payload: FetchRewardsBalancesPayload) => {
    const { config, userAddress, poolInfo } = payload
    if (!config || !config.stakeAddress || !userAddress || poolInfo.length === 0) {
      console.error('Invalid payload for fetching staked rewards')
      return {}
    }
    const aptosConfig = new AptosConfig({
      network: config.network as Network,
      fullnode: config.aptosNodeUrl,
      faucet: config.aptosFaucetUrl
    })

    if (!aptosConfig) {
      console.error('Invalid aptos config')
      return {}
    }

    const aptos = new Aptos(aptosConfig)

    if (!aptos) {
      console.error('Invalid aptos client')
      return {}
    }

    let rewardsBalances: { [rewardToken: string]: number } = {}

    try {
      await Promise.all(
        poolInfo.map(async (pool) => {
          if (pool.rewardTokens.length === 0) {
            return
          }

          //   if (!rewardsBalances[pool.poolAddress]) {
          //     rewardsBalances[pool.poolAddress] = {}
          //   }

          //TODO: iterate over reward tokens

          const balance = await aptos.view({
            payload: {
              function: `${config.stakeAddress}::multi_rewards::get_earned`,
              typeArguments: [],
              functionArguments: [userAddress, pool.poolAddress, pool.rewardTokens[0]]
            }
          })

          rewardsBalances[pool.rewardTokens[0]] = Number(balance)
        })
      )

      console.log('REWARDS BALANCES', rewardsBalances)

      return rewardsBalances
    } catch (e: any) {
      console.error('Error fetching staked rewards:', e)
      return {}
    }
  }
)
