import { Link, useLocation } from 'react-router-dom'
import CloseButton from 'components/common/CloseButton'

export function BurgerNav({ cb }: { cb: () => void }) {
  const { pathname } = useLocation()
  const homeSelected = pathname === '/'
  const selectedStyle = 'selected'
  const currentYear = new Date().getFullYear()

  return (
    <div className="burger-nav">
      {/* <img src={spBasic} alt="superposition" className="burg-logo-left" /> */}
      <CloseButton cb={cb} />
      <div className="burg-links">
        <Link to="/" onClick={cb} className={` ${homeSelected ? selectedStyle : ''}`}>
          Explore
        </Link>
      </div>
      <div className="bottom-links">
        <a href="/" target="_blank" rel="noreferrer">
          Website
        </a>
        <a href="/" target="_blank" rel="noreferrer">
          Docs
        </a>
        <a href="/" target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        <a href="/" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        <hr className="hr" />
        <p className="year">&copy;&nbsp;{currentYear}&nbsp;Canopy</p>
      </div>
    </div>
  )
}
