import { useAppSelector } from 'state/hooks'
import { VaultRow } from './VaultRow'
import {
  ANNUALIZED_RETURN,
  POSITION_VALUE,
  RISK_SCORE,
  selectExploreInput,
  selectExploreSortBy,
  TVL,
  WALLET_BALANCE
} from 'state/slices/ui/explore'
import { VaultRowSkeleton } from './VaultRowSkeleton'
import { isEmptyOrNil } from 'toolbox/account'
import { selectUnifiedVaultsLoaded, selectUnifiedVaultsWithBalances } from 'state/slices/app/vaults'
import { useMemo } from 'react'
import { UnifiedVault } from 'state/types'

export function VaultList({ minimal, scrolling }: { minimal?: boolean; scrolling?: boolean }) {
  const vaults = useAppSelector(selectUnifiedVaultsWithBalances)
  const vaultsLoaded = useAppSelector(selectUnifiedVaultsLoaded)
  const noVaults = isEmptyOrNil(vaults)
  const showSkeleton = !vaultsLoaded || noVaults
  const minimalClass = minimal ? 'minimal' : ''
  const sortBy = useAppSelector(selectExploreSortBy)
  const searchVal = useAppSelector(selectExploreInput)

  const filteredVaults = useMemo(() => {
    if (!searchVal.trim()) {
      return vaults
    }
    const filtered = vaults.filter((v) => {
      const labelsString = v.meta.labels.join(' ')
      return (
        v.assetName.toLowerCase().includes(searchVal.toLowerCase()) ||
        v.coinType.toLowerCase().includes(searchVal.toLowerCase()) ||
        labelsString.toLowerCase().includes(searchVal.toLowerCase())
      )
    })
    return filtered.length > 0 ? filtered : vaults
  }, [vaults, searchVal])

  const { vaultsWithPositions, vaultsWithoutPositions } = useMemo(() => {
    return filteredVaults.reduce(
      (acc, vault) => {
        if (vault.userPosition?.balance && vault.userPosition.balance > 0) {
          acc.vaultsWithPositions.push(vault)
        } else {
          acc.vaultsWithoutPositions.push(vault)
        }
        return acc
      },
      { vaultsWithPositions: [], vaultsWithoutPositions: [] } as {
        vaultsWithPositions: typeof vaults
        vaultsWithoutPositions: typeof vaults
      }
    )
  }, [filteredVaults])

  if (showSkeleton) {
    return (
      <div className="vaults-container">
        <div className="scroll-content">
          <VaultRowSkeleton minimal={minimal} />
          <VaultRowSkeleton minimal={minimal} />
          <VaultRowSkeleton minimal={minimal} />
          <VaultRowSkeleton minimal={minimal} />
          <VaultRowSkeleton minimal={minimal} />
          <VaultRowSkeleton minimal={minimal} />
          <VaultRowSkeleton minimal={minimal} />
        </div>
      </div>
    )
  }

  const sortedPositionalVaults = [...vaultsWithPositions].sort((a, b) =>
    sortVaultsByMetric(a, b, sortBy)
  )

  const sortedNonPositionalVaults = [...vaultsWithoutPositions].sort((a, b) =>
    sortVaultsByMetric(a, b, sortBy)
  )

  const positionalDisplay = sortedPositionalVaults.map((vault, i) => (
    <VaultRow key={i + vault.coinType} vault={vault} minimal={minimal} />
  ))

  const nonPositionalDisplay = sortedNonPositionalVaults.map((vault, i) => (
    <VaultRow key={i + vault.coinType} vault={vault} minimal={minimal} />
  ))

  const hasPositions = !isEmptyOrNil(sortedPositionalVaults)

  return (
    <div
      className={`vaults-container ${minimalClass} ${
        scrolling ? 'vaults-container-scrolling' : ''
      }`}>
      <div className={`scroll-content`}>
        {positionalDisplay}
        {!minimal && hasPositions && <div className="horizontal-bar" />}
        {nonPositionalDisplay}
      </div>
    </div>
  )
}

export function sortVaultsByMetric(a: UnifiedVault, b: UnifiedVault, sortBy: string) {
  switch (sortBy) {
    case ANNUALIZED_RETURN:
      return b.supplyAPR - a.supplyAPR
    case TVL:
      return b.tvlUSD - a.tvlUSD
    case WALLET_BALANCE:
      return (b?.walletBalance ?? 0) - (a?.walletBalance ?? 0)
    case RISK_SCORE:
      return a.meta.riskScore - b.meta.riskScore
    case POSITION_VALUE:
      return (b?.userPosition?.value ?? 0) - (a?.userPosition?.value ?? 0)
    default:
      return 0
  }
}
