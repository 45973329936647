import search from 'assets/icons/searchIcon.svg'
import { useState, useRef, useEffect } from 'react'
import { onlyAlpha } from 'toolbox/format'

export interface SearchBarProps {
  value: string
  update: (s: string) => void
  searchFn?: () => void
  placeHolder?: string
  forceOpen?: boolean
}

function SearchBar({ value, update, searchFn, placeHolder, forceOpen }: SearchBarProps) {
  const [isSearchOpen, setIsSearchOpen] = useState(forceOpen)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isSearchOpen) {
      inputRef.current?.focus()
    } else {
      inputRef.current?.blur()
    }
  }, [isSearchOpen])

  return (
    <div className={`search-container ${isSearchOpen ? 'open' : ''}`}>
      <div className="search-icon-container" onClick={() => setIsSearchOpen(!isSearchOpen)}>
        <img src={search} alt="search" />
      </div>
      {!!isSearchOpen && (
        <input
          ref={inputRef}
          value={value}
          onChange={(e) => update(onlyAlpha(e.target.value))}
          placeholder={placeHolder ? placeHolder : 'Search'}
        />
      )}
      {searchFn && <button onClick={searchFn}>Search</button>}
    </div>
  )
}

export default SearchBar
