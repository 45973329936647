import { onlyNumbers } from 'toolbox/format'
import { TokenPill } from './TokenPill'
import { setTokenSelectOpenState } from 'state/slices/ui/form'
import { TokenDropButton } from './TokenDropButton'

export interface SimpleInputProps {
  inputVal: string
  setInputVal: (val: string) => void
  inputDetail: string
  logo: string
  ticker: string
}

export function SimpleInput({
  inputVal,
  setInputVal,
  inputDetail,
  logo,
  ticker
}: SimpleInputProps) {
  const noActive = !ticker || ticker === ''
  return (
    <div className="tx-input">
      <div className="tx-input-stack">
        <input
          className="tx-input-field"
          type="text"
          placeholder="0.00"
          value={inputVal}
          onChange={(e) => setInputVal(onlyNumbers(e.target.value))}
        />
        <p className="tx-input-detail">{inputDetail}</p>
      </div>
      <TokenDropButton logo={logo} ticker={ticker} callback={() => setTokenSelectOpenState(true)} />
    </div>
  )
}
