import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { fetchCanopyMeta } from 'state/thunks/fetchCanopyMeta'
import { isEmptyOrNil } from 'toolbox/account'
import { CanopyMeta } from 'state/types'

type TokensState = CanopyMeta[]

type State = Loadable<TokensState>
export const initialState = initLoadable<TokensState>([])

const canopyMeta = createSlice({
  name: 'canopyMeta',
  initialState,
  reducers: {
    resetCanopyMeta: (state: State) => {
      state.value = []
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCanopyMeta.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchCanopyMeta.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export const { resetCanopyMeta } = canopyMeta.actions
export default canopyMeta.reducer
export const selectCanopyMeta = (s: RootState) => s.app.canopyMeta.value
export const selectCanopyMetaBusy = (s: RootState) => s.app.canopyMeta.status === 'busy'
export const selectCanopyMetaLoadedOnce = (s: RootState) => s.app.canopyMeta.loadedOnce

export const selectLoadedCanopyMeta = createSelector(
  [selectCanopyMeta, selectCanopyMetaLoadedOnce],
  (canopyMeta, loaded) => {
    if (!loaded) {
      return []
    }
    if (isEmptyOrNil(canopyMeta)) {
      return []
    }

    return canopyMeta
  }
)

export const findMetaByVaultAddress = (vaultAddress: string, canopyMeta: CanopyMeta[]) =>
  canopyMeta.find((token) => token.networkAddress.toLowerCase() === vaultAddress.toLowerCase())

export const selectMetaByVaultAddress = (vaultAddress: string) =>
  createSelector([selectCanopyMeta], (canopyMeta): CanopyMeta | undefined => {
    if (isEmptyOrNil(canopyMeta)) {
      return undefined
    }

    return canopyMeta.find(
      (token) => token.networkAddress.toLowerCase() === vaultAddress.toLowerCase()
    )
  })
