// import tooltipIcon from 'assets/tooltips/tooltip.svg'

import { formatPercentage, formatPercentageWithSign } from 'toolbox/format'

export interface SubValue {
  subVal: number
  isPostive: boolean
}

export interface DataPointProps {
  label: string
  value?: number | string
  subValue?: SubValue
  secondaryValue?: number | string
  isLoading?: boolean
  horizontal?: boolean
  right?: boolean
  tooltip?: string
  colorBox?: string
}

const DataPoint = ({
  label,
  value,
  secondaryValue,
  horizontal,
  right,
  tooltip,
  colorBox,
  subValue
}: DataPointProps) => {
  const hrz = horizontal ? 'is-horizontal' : ''
  const rt = right ? 'is-right' : ''
  const dis =
    value === '--' || value === '0' || value === '$0' || value === '$0.00' || value === 'N/A'
      ? 'disabled'
      : ''
  if (value === '$0' || value === '$0.00') {
    value = '0'
  }
  const thin = tooltip ? 'thin-lined' : ''
  const positiveClass = subValue?.isPostive ? 'positive' : 'negative'
  return (
    <div className={'outer-data-point'}>
      {colorBox && <div className={`color-box ${colorBox}`} />}
      <div className={`data-point ${hrz} ${rt} ${thin}`}>
        <div className="label-wrap">
          <div className="tip-box">
            <p className={`label tooltip`}>{label}</p>
            {!!tooltip && (
              <div className="tip-value">
                <p>{tooltip}</p>
              </div>
            )}
          </div>
        </div>
        {value && <p className={`value ${dis} `}>{value}</p>}
        {subValue && (
          <p className={`sub-value ${positiveClass}`}>{formatPercentage(subValue.subVal)}</p>
        )}
      </div>
      {secondaryValue && <p className={`secondary-value ${dis}`}>{secondaryValue}</p>}
    </div>
  )
}

export default DataPoint
