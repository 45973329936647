import Tab, { TabProps } from './Tab'

export type TabVars = 'basic' | 'tab-link'
export type TabMods = 'full-width'

export interface TabsProps {
  tabs: TabProps[]
  variant: TabVars
  mods?: TabMods
  disableTabSwitch?: boolean
}

function Tabs({ tabs, variant, mods, disableTabSwitch }: TabsProps) {
  function toTab(t: TabProps, i: number) {
    return (
      <Tab
        label={t.label}
        active={t.active}
        onClick={t.onClick}
        key={i}
        disabled={disableTabSwitch}
      />
    )
  }
  return <div className={`tabs ${variant} ${mods && mods}`}>{tabs.map(toTab)}</div>
}

export default Tabs
