import { useMemo, useState, memo } from 'react'
import { isEmptyOrNil } from 'toolbox/account'
import { LineItem, LineItemProps } from './LineItem'

export interface LineItemListProps {
  items: LineItemProps[]
  more?: LineItemProps[]
  tight?: boolean
  next?: string
}
const LineItemMemo = memo(LineItem)

function LineItemList({ items, tight, more }: LineItemListProps) {
  const displayList = useMemo(
    () => items.map((p, i) => p && <LineItemMemo {...p} key={i} />),
    [items]
  )
  const displayMore = useMemo(
    () => more?.map((p, i) => p && <LineItemMemo {...p} key={i} />),
    [more]
  )

  const t = tight ? 'tight' : ''
  const hasMore = !isEmptyOrNil(more)
  const [showMore, setShowMore] = useState(false)
  const buttonText = showMore ? 'LESS' : 'MORE'

  return (
    <div className={`line-item-list ${t}`}>
      <>
        {displayList}
        {!!hasMore && (
          <div className="more-container" onClick={() => setShowMore(!showMore)}>
            <div className="line" />
            <button className="btn transparent">{buttonText}</button>
            <div className="line" />
          </div>
        )}
        {!!showMore && displayMore}
      </>
    </div>
  )
}

export default LineItemList
