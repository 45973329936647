import { createAsyncThunk } from '@reduxjs/toolkit'
import { GET_CANOPY_METADATA } from 'state/graphql/gqlQueries'
import { CanopyMeta } from 'state/types'
import { ApolloClient, InMemoryCache } from '@apollo/client'

export const fetchCanopyMeta = createAsyncThunk(
  'canopyMeta/fetch',
  async (_, { dispatch }): Promise<CanopyMeta[]> => {
    const uri = process.env.REACT_APP_METAGQL_URL

    let apollo = new ApolloClient({
      uri,
      cache: new InMemoryCache(),
      headers: {
        'x-api-key': process.env.REACT_APP_METAGQL_API_KEY || '',
        'Content-Type': 'application/json'
      }
    })

    try {
      const tokenMeta = await apollo.query({
        query: GET_CANOPY_METADATA
      })
      const items = tokenMeta.data.listCanopyMetadata.items
      console.log('CanopyMeta:', items)

      return items
    } catch (e: any) {
      console.error('Error fetching GQL token metadata:', e)
      throw e
    }
  }
)
