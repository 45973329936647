import { TxMultiStep } from 'state/slices/ui/form'
import { StepRow } from './StepRow'

export interface MultiStepProps {
  steps: TxMultiStep
  info: string
}

export function MultiStep({ steps, info }: MultiStepProps) {
  const stepsDisplay = steps.map((s, i) => (
    <StepRow key={i} index={i} label={s.label} status={s.status} />
  ))

  return (
    <div className="multi-step">
      <p className="multi-step-info">{info}</p>
      <div className="multi-step-list">{stepsDisplay}</div>
    </div>
  )
}
