import { createAsyncThunk } from '@reduxjs/toolkit'
import { logToastThrow, toastLoad, toastSuccess } from 'toolbox/toast'
import { SignAndSubmitTransactionCallback, UnifiedVault } from 'state/types'
import { postTransactionRefresh } from 'state/fetch'
import {
  DEPOSIT_TAB,
  FormTab,
  LIQUID_MULTI_STEPS,
  MULTI_COMPLETED,
  MULTI_PENDING,
  resetMultiStepState,
  setFormOpenState,
  TxMultiStep,
  updateMultiStepState
} from 'state/slices/ui/form'
import { scaleUp } from 'toolbox/format'
import { setTxSuccessState, txStatusIdle, txStatusPending } from 'state/slices/ui/transaction'
import { Aptos, Hex, Network, PublicKey } from '@aptos-labs/ts-sdk'
import {
  normalizeHexAddress,
  signAndSubmitterBasic,
  updateSuccessStateAndWait
} from 'toolbox/aptos-util'
import { CoinTypeWithDecimals } from 'state/slices/app/vaults'
import { AptosTx } from 'toolbox/aptosTx'
import { wrapUp } from './doTxLiquid'

export interface TxReqPayloadLiquidStakeOnly {
  formTab: FormTab
  amount: number
  vault: UnifiedVault
  address: string
  signAndSub: SignAndSubmitTransactionCallback
  aptos: Aptos
  envNetwork: Network
  API_URL: string
  echelonRootAddress: string
  nodeUrl: string
  publicKey: PublicKey
  coinTypeWithDecimals: CoinTypeWithDecimals[]
  stakeAddress: string
}

const ICHI_ROOT = '0x3356bce7af6eb2c332a6e944eb54c6383fec0d099af62e1618a29c7ea8e6cbc7'
const SLIPPAGE_HARD = 0.01

export const doTxLiquidStakeOnly = createAsyncThunk<any, TxReqPayloadLiquidStakeOnly>(
  'market/doTxLiquidStakeOnly',
  async (payload: TxReqPayloadLiquidStakeOnly): Promise<any> => {
    try {
      toastLoad('Building transaction...')
      txStatusPending({ type: payload.formTab, info: 'Signing transaction...' })

      const { vault, envNetwork, aptos } = payload

      if (!vault || !envNetwork || !aptos) {
        logToastThrow(
          'Missing required system configuration',
          'Check the console for more information'
        )
      }

      const aptosTx = new AptosTx(ICHI_ROOT, payload.stakeAddress)

      if (!payload.nodeUrl || !envNetwork || !aptos) {
        console.log('Env network: ', envNetwork)
        console.log('aptosSDK: ', aptos)
        logToastThrow(
          'Missing required system configuration',
          'Check the console for more information'
        )
      }

      const { amount, address, API_URL } = payload
      if (!vault || !amount || !address) {
        logToastThrow(
          'Missing arguments',
          'The transaction request is missing required arguments, please try again'
        )
      }

      const { coinType, meta, decimals, ichiVault } = vault

      const amountRaw = Math.floor(Number(scaleUp(amount, decimals)))
      const BIN_STEP =
        '0x19b400ef28270cdd00ff826412a13b2e7d82a8a0762c46bed34a6e8d52f0275a::bin_steps::X20'

      try {
        toastLoad('Staking and subscribing...')
        const pools = [
          '0x71a0ab627167f1aa11985552b60d119855a463eeb10c7f28da2aef081975df45',
          '0x36e56f9ffe148e97c66477ab64d9ca5c94edd6af450a8530bd2d4f74571ad23'
        ]

        let unsubscribedPools: string[] = []

        try {
          const poolSubscriptionStatus = await Promise.all(
            pools.map(async (pool) => {
              const isSubscribed = await aptos.view({
                payload: {
                  function: `${payload.stakeAddress}::multi_rewards::is_user_subscribed`,
                  typeArguments: [],
                  functionArguments: [address, pool]
                }
              })
              return { pool, isSubscribed: isSubscribed[0] }
            })
          )

          unsubscribedPools = poolSubscriptionStatus
            .filter(({ isSubscribed }) => !isSubscribed)
            .map(({ pool }) => pool)

          console.log('Pool subscription status:', poolSubscriptionStatus)
          console.log('Unsubscribed pools:', unsubscribedPools)
        } catch (e: any) {
          console.error(e)
          logToastThrow('Error fetching pool subscription status', e.message)
        }
        const hasUnsubscribedPools = unsubscribedPools.length > 0

        const creator = ichiVault?.token_id_creator || ''
        const collection = ichiVault?.collection || ''
        const name = ichiVault?.token_id_name || ''
        const propertyVersion = '0'

        const stakeAndSubscribeTxPayload = aptosTx.stakeAndSubscribeIx({
          sender: address,
          pools: hasUnsubscribedPools ? unsubscribedPools : [],
          amount: amountRaw.toString(),
          creator,
          collection,
          name,
          propertyVersion
        })

        const stakeIxPayload = aptosTx.stakeIx({
          sender: address,
          amount: amountRaw.toString(),
          creator,
          collection,
          name,
          propertyVersion
        })

        const usePayload = hasUnsubscribedPools ? stakeAndSubscribeTxPayload : stakeIxPayload
        console.log('USE PAYLOAD', usePayload)

        const hash = await signAndSubmitterBasic({
          signAndSub: payload.signAndSub,
          ixData: usePayload
        })
        console.log('Stake TX HASH:', hash)

        const stakeAndSubscribeResult = await updateSuccessStateAndWait({
          hash: hash.hash,
          aptos,
          action: payload.formTab
        })

        console.log('Stake and subscribe result:', stakeAndSubscribeResult)
        updateMultiStepState(1, MULTI_COMPLETED)
      } catch (e: any) {
        console.error(e)
        logToastThrow('Transaction failed', e.message)
      }

      toastSuccess('Transaction completed!')
      //reset and close
      postTransactionRefresh(
        address,
        payload.coinTypeWithDecimals,
        API_URL,
        aptos,
        payload.echelonRootAddress,
        payload.nodeUrl
      )
      wrapUp()
    } catch (e: any) {
      console.error(e)
      logToastThrow('Transaction failed', e.message)
    }
  }
)
