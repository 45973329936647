import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { fetchStakedBalances } from 'state/thunks/fetchStakedBalances'

export interface BalanceMap {
  [key: string]: number
}

type State = Loadable<BalanceMap>
export const initialState = initLoadable<BalanceMap>({})

const stakedBalances = createSlice({
  name: 'stakedBalances',
  initialState,
  reducers: {
    resetStakedBalances: (state: State) => {
      state.value = {}
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStakedBalances.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchStakedBalances.pending, (state) => {
      state.status = 'busy'
    })

    builder.addCase(fetchStakedBalances.rejected, (state) => {
      state.status = 'errored'
    })
  }
})

export const { resetStakedBalances } = stakedBalances.actions
export default stakedBalances.reducer
export const selectStakedBalances = (s: RootState) => s.user.stakedBalances.value
export const selectStakedBalancesBusy = (s: RootState) => s.user.stakedBalances.status === 'busy'
export const selectStakedBalancesLoaded = (s: RootState) => s.user.stakedBalances.loadedOnce
export const selectStakedBalancesErrored = (s: RootState) =>
  s.user.stakedBalances.status === 'errored'

export const selectStakedBalance = createSelector(
  [
    selectStakedBalances,
    selectStakedBalancesLoaded,
    (_state: RootState, tokenKey: string) => tokenKey
  ],
  (stakedBalances, loaded, tokenKey) => {
    if (!loaded || !stakedBalances) {
      return 0
    }
    return stakedBalances[tokenKey] ?? 0
  }
)
