interface ChartLabelProps {
  value: any
  secondValue?: any
  thirdValue?: any
  labels: any
}

export const ChartLabel = ({
  value,
  secondValue = null,
  thirdValue = null,
  labels
}: ChartLabelProps) => {
  return (
    <div className="chart-label-container">
      <div className="label-row">
        <span className="label-name">{labels.value || 'Total Lend'}:</span>
        <span className="label-value">{value}</span>
      </div>
      {secondValue && (
        <div className="label-row">
          <span className="label-name">{labels.secondValue || 'Total Borrow'}:</span>
          <span className="label-value">{secondValue}</span>
        </div>
      )}
      {thirdValue && (
        <div className="label-row">
          <span className="label-name">{labels.thirdValue || 'Utilization'}:</span>
          <span className="label-value">{thirdValue}</span>
        </div>
      )}
    </div>
  )
}
