import { GradientBoxText } from 'components/common/GradientBoxText'
import { QuickDepositForm } from 'components/form/QuickDepositForm'
import { useAppSelector } from 'state/hooks'
import { selectActiveVault } from 'state/slices/ui/form'

export function Earn() {
  const activeVault = useAppSelector(selectActiveVault)
  return (
    <div className="earn">
      <div className="inner-earn">
        <h2 className="earn-title">
          Earn with <GradientBoxText text="every" /> asset
        </h2>
        <QuickDepositForm activeVault={activeVault} />
      </div>
    </div>
  )
}
