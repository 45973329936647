import { UnifiedVault } from 'state/types'
import { MetaToken, MetaTokenProps } from './MetaToken'
import { prettyTokenBal } from 'toolbox/format'
import { setActiveVaultState } from 'state/slices/ui/form'

export interface TokenSelectRowProps {
  vault: UnifiedVault
  walletBalance: number
}

export function TokenSelectRow({ vault, walletBalance }: TokenSelectRowProps) {
  const { meta } = vault
  const { iconURL, displayName } = meta

  const tokenMeta: MetaTokenProps = {
    icon: iconURL,
    ticker: displayName
  }

  return (
    <div className="token-select-row" onClick={() => setActiveVaultState(vault)}>
      <MetaToken {...tokenMeta} />
      {walletBalance > 0 && (
        <p className="token-select-row-balance">{prettyTokenBal(walletBalance)}</p>
      )}
    </div>
  )
}
