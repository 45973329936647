import { createSlice } from '@reduxjs/toolkit'
import { initLoadable } from 'state'
import { RootState } from 'state/store'
import { createSelector } from 'reselect'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchBrokers } from 'state/thunks/fetchBrokers'
import { NameBalanceMap, PricedInstrument, SBroker, UnifiedVault } from 'state/types'
import {
  calcLendRate,
  calcUnderlyingFromNoteBalance,
  selectFormattedPositions
} from '../user/portfolio'
import { scaleDown } from 'toolbox/format'
import { findMetaByVaultAddress, selectLoadedCanopyMeta } from './canopyMeta'

const initialState = initLoadable<SBroker[]>([])

export const brokers = createSlice({
  name: 'brokers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBrokers.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchBrokers.pending, (state) => {
      state.status = 'busy'
    })

    builder.addCase(fetchBrokers.rejected, (state) => {
      state.status = 'errored'
    })
  }
})

export default brokers.reducer
export const selectBrokers = (s: RootState) => s.app.brokers.value
export const selectBrokersBusy = (s: RootState) => s.app.brokers.status === 'busy'
export const selectBrokersLoaded = (s: RootState) => s.app.brokers.loadedOnce
export const selectBrokersErrored = (s: RootState) => s.app.brokers.status === 'errored'

export const DEPOSIT_NOTE = 'DepositNote'
export const LOAN_NOTE = 'LoanNote'

export const selectBrokersAsUnified = createSelector(
  [selectBrokersLoaded, selectBrokers, selectLoadedCanopyMeta, selectFormattedPositions],
  (brokersLoaded, brokers, canopyMeta, formattedPositions) => {
    if (!brokersLoaded) {
      return []
    }
    const unifiedVaults = brokers
      ?.map((b: SBroker) => {
        const cMeta = findMetaByVaultAddress(b.networkAddress, canopyMeta)
        if (!cMeta) return null
        // if (cMeta.iconURL === '') return null
        const noteBalance = formattedPositions?.collaterals[b.depositNote.name] || 0
        const userPosition = calcUnderlyingFromNoteBalance(noteBalance, b)
        const tvl = calcBrokerTotalLendDollars(b)
        const supplyAPR = calcLendRate(b.interestRate, b.interestFeeRate, b.utilization)

        const modifiedMeta =
          cMeta.displayName === 'APTOS'
            ? {
                ...cMeta,
                iconURL: 'https://ichi-images.s3.us-east-1.amazonaws.com/tokens/logo_256_apt.svg'
              }
            : cMeta

        return {
          coinType: b.underlyingAsset.networkAddress,
          supplyAPR,
          decimals: b.underlyingAsset.decimals,
          assetPrice: b.underlyingAsset.price,
          assetName: b.underlyingAsset.name,
          totalDeposit: b.scaledAvailableLiquidityUnderlying + b.scaledTotalBorrowedUnderlying,
          totalBorrow: b.scaledTotalBorrowedUnderlying,
          totalShares: scaleDown(Number(b.depositNoteSupply), b.depositNote.decimals),
          tvlUSD: tvl,
          meta: modifiedMeta,
          userPosition
        }
      })
      .filter(Boolean) as UnifiedVault[]
    return unifiedVaults ? unifiedVaults : []
  }
)

export const selectPricesFromBrokers = createSelector(
  [selectBrokersLoaded, selectBrokers],
  (loaded, brokers) => {
    if (!loaded) {
      return {}
    }
    const prices: NameBalanceMap = {}
    brokers?.forEach((b) => {
      prices[b.underlyingAsset.name] = b.underlyingAsset.price
      prices[b.depositNote.name] = b.depositNote.price
      prices[b.loanNote.name] = b.loanNote.price
    })
    return prices
  }
)

export const selectAptPrice = (s: RootState) =>
  s.app.brokers.value.find((b) => b.underlyingAsset.name === 'aptos')?.underlyingAsset.price

export const selectUnderlyingNamesFromBrokers = createSelector(
  [selectBrokersLoaded, selectBrokers],
  (loaded, brokers) => {
    if (!loaded) {
      return []
    }
    const names: string[] = []
    brokers?.forEach((b) => {
      names.push(b.underlyingAsset.name)
    })
    return names
  }
)

export const selectCoinTypes = createSelector(
  [selectBrokersLoaded, selectBrokers],
  (loaded, brokers) => {
    if (!loaded) {
      return []
    }
    const coinTypes: string[] = []
    brokers?.forEach((b) => {
      coinTypes.push(b.underlyingAsset.networkAddress)
    })
    return coinTypes
  }
)

export const selectInstruments = createSelector(
  [selectBrokersLoaded, selectBrokers],
  (loaded, brokers) => {
    if (!loaded) {
      return []
    }
    if (isEmptyOrNil(brokers)) {
      return []
    }
    const instruments: PricedInstrument[] = []
    brokers?.forEach((b) => {
      instruments.push(b.underlyingAsset)
      instruments.push(b.depositNote)
      instruments.push(b.loanNote)
    })
    return instruments
  }
)

export const calcBrokerTotalLendDollars = (broker: SBroker) => {
  if (isEmptyOrNil(brokers)) {
    return 0
  }

  if (!broker) {
    return 0
  }
  const underlyingPrice = broker.underlyingAsset.price
  const available = Number(broker.scaledAvailableLiquidityUnderlying)
  const borrowing = Number(broker.scaledTotalBorrowedUnderlying)
  const total = (available + borrowing) * underlyingPrice
  return total
}
