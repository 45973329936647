import { LayerToken } from './LayerToken'
import { Pill, PillProps } from './Pill'

export interface MetaTokenProps {
  ticker: string
  icon: string
  small?: boolean
  pills?: PillProps[]
  useLayerToken?: boolean
}

export function MetaToken({ ticker, icon, pills, small, useLayerToken }: MetaTokenProps) {
  const smallClass = small ? 'small' : ''
  const hasPills = pills && pills.length > 0

  const pillsElements = pills?.map((label, i) => (
    <Pill key={i} text={label.text} colorClass={label.colorClass} />
  ))
  const useLayerTokenClass = useLayerToken ? 'layer-token-margin' : ''
  return (
    <div className={`meta-token ${smallClass}`}>
      {useLayerToken && <LayerToken />}
      {!useLayerToken && <img src={icon} alt={ticker} />}
      <div className={'meta-text ' + useLayerTokenClass}>
        <p className="meta-ticker">{ticker}</p>
        {hasPills && <div className="meta-pills">{pillsElements}</div>}
      </div>
    </div>
  )
}
