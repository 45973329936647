import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, dispatch } from 'state/store'

export interface TransactionState {
  txStatus: 'idle' | 'pending'
  txType: string
  txInfo: string
  txSuccess: TxSuccess | null
  txError: TxError | null
}

export const initialState: TransactionState = {
  txStatus: 'idle',
  txType: '',
  txInfo: '',
  txSuccess: null,
  txError: null
}

export interface TxInfo {
  type: string
  info: string
}

export const DEPOSIT_ACTION = 'deposited'
export const WITHDRAW_ACTION = 'withdrawn'

export type TxAction = typeof DEPOSIT_ACTION | typeof WITHDRAW_ACTION

export interface TxSuccess {
  amount: string
  ticker: string
  txHash: string
  decimals: number
  action: TxAction
}

export interface TxError {
  title: string
  message: string
  explorerHash?: string
}

export interface AppError {
  title: string
  message: string
  details: any
  clear: () => void
}

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTxStatusPending: (s, a: PayloadAction<TxInfo>) => {
      s.txStatus = 'pending'
      s.txType = a.payload.type
      s.txInfo = a.payload.info
    },
    setTxStatusIdle: (s) => {
      s.txStatus = 'idle'
      s.txType = ''
      s.txInfo = ''
    },
    setTxSuccess: (s, a: PayloadAction<TxSuccess>) => {
      s.txSuccess = a.payload
      s.txStatus = 'idle'
      s.txType = ''
      s.txInfo = ''
    },
    seTxError: (s, a: PayloadAction<TxError>) => {
      s.txError = a.payload
      s.txStatus = 'idle'
      s.txType = ''
      s.txInfo = ''
    }
  },
  extraReducers: (builder) => {}
})

export const selectTxStatus = (s: RootState) => s.ui.transaction.txStatus
export const selectTxPendingType = (s: RootState) => s.ui.transaction.txType
export const selectTxInfo = (s: RootState) => s.ui.transaction.txInfo
export const selectTxSuccess = (s: RootState) => s.ui.transaction.txSuccess
export const selectTxError = (s: RootState) => s.ui.transaction.txError

export default slice.reducer

//selectors
const { setTxStatusPending, setTxStatusIdle, setTxSuccess, seTxError } = slice.actions

//dispatches
export const txStatusPending = (tx: TxInfo) => {
  dispatch(setTxStatusPending(tx))
}
export const txStatusIdle = () => {
  dispatch(setTxStatusIdle())
}
export const setTxSuccessState = (tx: TxSuccess) => {
  dispatch(setTxSuccess(tx))
}

export const setTxErrorState = (tx: TxError) => {
  dispatch(seTxError(tx))
}
