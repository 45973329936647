import { createAsyncThunk } from '@reduxjs/toolkit'
import { BrokerHistory } from 'state/slices/app/brokerHistory'

export const fetchBrokerHistory = createAsyncThunk(
  'brokerHistory/fetchAll',
  async ({
    url,
    name,
    from,
    to,
    offset,
    size
  }: {
    url: string
    name: string
    from: Date
    to: Date
    offset: number
    size: number
  }): Promise<BrokerHistory[]> => {
    try {
      console.log('fetching History...')
      const queryParams = new URLSearchParams({
        from: from.toISOString(),
        to: to.toISOString(),
        offset: offset.toString(),
        size: size.toString()
      })
      const historyRes = await fetch(`${url}/brokers/history/${name}?${queryParams}`)
      const history = await historyRes.json()
      console.log('History: ', history.history)
      return history.history
    } catch (error: any) {
      console.error('Error fetching broker histories:', error)
      throw error
    }
  }
)
