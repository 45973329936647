export interface TabProps {
  label: string
  active: boolean
  onClick: () => void
  disabled?: boolean
}

function Tab({ label, active, onClick, disabled }: TabProps) {
  const activeClass = active ? 'active' : ''
  const disabledClass = disabled ? 'disabled' : ''
  return (
    <>
      <button onClick={onClick} className={`${activeClass} ${disabledClass}`} disabled={disabled}>
        {label}
      </button>
    </>
  )
}

export default Tab
