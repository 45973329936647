import React from 'react'

export interface DoubleDataPointProps {
  topLabel: string
  topValue: string | number
  bottomLabel: string
  bottomValue: string | number
  showTop?: boolean
}

const DoubleDataPoint: React.FC<DoubleDataPointProps> = ({
  topLabel,
  topValue,
  bottomLabel,
  bottomValue,
  showTop
}) => {
  return (
    <div className="double-data-point">
      {showTop && (
        <div className="top">
          <p className="label">{topLabel}</p>
          <p className="value">{topValue}</p>
        </div>
      )}
      <div className={showTop ? 'bottom' : 'bottom-no-position'}>
        <p className="label">{bottomLabel}</p>
        <p className="value">{bottomValue}</p>
      </div>
    </div>
  )
}

export default DoubleDataPoint
