import { formatCurrency } from 'toolbox/format'

export interface TxInputReviewProps {
  inputVal: string
  price: number
  ticker: string
}

export function TxInputReview({ inputVal, price, ticker }: TxInputReviewProps) {
  const val = Number(inputVal) * price
  return (
    <div className="tx-input-review">
      <p className="label">Amount</p>
      <p className="review-bal">
        {inputVal} {ticker}
      </p>
      <p className="review-val">{formatCurrency(val)}</p>
    </div>
  )
}
