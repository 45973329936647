import { formatCurrencyPrecise } from 'toolbox/format'

import rewardIcon from 'assets/icons/rewards.svg'

export interface RewardsPillProps {}

export function RewardsPillTotal({ value }: { value: number }) {
  return (
    <>
      <div className="rewards-pill eclipse full">
        <div className="rewards-content">
          <img src={rewardIcon} alt="reward" />
          <p className="rewards-title">Your unclaimed rewards</p>
        </div>
        <p className="rewards-value">{value ? formatCurrencyPrecise(value) : '0'}</p>
      </div>
    </>
  )
}
