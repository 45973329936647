import { combineReducers } from 'redux'
import { app } from './app'
import { user } from './user'
import { ui } from './ui'

export const rootReducer = combineReducers({
  app,
  user,
  ui
})
