import React, { useState } from 'react'
import downChev from 'assets/icons/down-chev-secondary.svg'
import { selectExploreSortBy, setExploreSortBy } from 'state/slices/ui/explore'
import { useAppSelector } from 'state/hooks'
import check from 'assets/icons/check.svg'

interface DropdownProps {
  options: string[]
  selectedOption: string
  callback: (option: string) => void
  defaultOption?: string
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  defaultOption = options[0],
  callback,
  selectedOption
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleOptionClick = (option: string) => {
    callback(option)
    setIsOpen(false)
  }

  return (
    <div className="dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        <p>{selectedOption}</p>
        <img src={downChev} alt="down-chevron" />
      </div>
      {isOpen && (
        <ul className="dropdown-options">
          {options.map((option) => (
            <li
              key={option}
              className={`dropdown-option ${option === selectedOption ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option)}>
              <span>{option}</span>
              {option === selectedOption && <img src={check} alt="selected" />}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Dropdown
