import { InputTransactionData } from '@aptos-labs/wallet-adapter-core'
import {
  ClaimArgs,
  DepositArgs,
  StakeAndSubscribeArgs,
  StakeArgs,
  WithdrawArgs
} from '../state/types'

export class AptosTx {
  private vaultsAddress: string
  private stakeAddress: string

  constructor(vaultsAddress: string, stakeAddress: string) {
    this.vaultsAddress = vaultsAddress
    this.stakeAddress = stakeAddress
  }

  depositIx({
    sender,
    coinTypeX,
    coinTypeY,
    binStep,
    vault,
    activeBinDesired,
    activeBinSlippage,
    amountX,
    amountY,
    minShares
  }: DepositArgs): InputTransactionData {
    return {
      sender,
      data: {
        function: `${this.vaultsAddress}::entry::deposit`,
        typeArguments: [coinTypeX, coinTypeY, binStep],
        functionArguments: [vault, activeBinDesired, activeBinSlippage, amountX, amountY, minShares]
      }
    }
  }

  withdrawIx({
    sender,
    coinTypeX,
    coinTypeY,
    binStep,
    vault,
    amount
  }: WithdrawArgs): InputTransactionData {
    return {
      sender,
      data: {
        function: `${this.vaultsAddress}::entry::withdraw`,
        typeArguments: [coinTypeX, coinTypeY, binStep],
        functionArguments: [vault, amount]
      }
    }
  }

  stakeAndSubscribeIx({
    sender,
    pools,
    creator,
    collection,
    name,
    amount,
    propertyVersion
  }: StakeAndSubscribeArgs): InputTransactionData {
    return {
      sender,
      data: {
        function: `${this.stakeAddress}::router::stake_and_subscribe_token`,
        typeArguments: [],
        functionArguments: [pools, amount, creator, collection, name, propertyVersion]
      }
    }
  }

  stakeIx({
    sender,
    amount,
    creator,
    collection,
    name,
    propertyVersion
  }: StakeArgs): InputTransactionData {
    return {
      sender,
      data: {
        function: `${this.stakeAddress}::router::stake_token`,
        typeArguments: [],
        functionArguments: [amount, creator, collection, name, propertyVersion]
      }
    }
  }

  claimIx({ sender, stakingTokens }: ClaimArgs): InputTransactionData {
    return {
      sender,
      data: {
        function: `${this.stakeAddress}::router::claim_rewards`,
        typeArguments: [],
        functionArguments: [stakingTokens]
      }
    }
  }
}
