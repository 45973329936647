import { ApolloClient, InMemoryCache } from '@apollo/client'

let apolloClient: ApolloClient<any> | null = null

export const getIndexerClient = () => {
  if (!apolloClient) {
    apolloClient = new ApolloClient({
      uri: process.env.REACT_APP_INDEXER_URL,
      cache: new InMemoryCache(),
      headers: {
        'api-key': process.env.REACT_APP_INDEXER_API_KEY || '',
        'Content-Type': 'application/json'
      }
    })
  }
  return apolloClient
}

export const getMetaClient = () => {
  if (!apolloClient) {
    apolloClient = new ApolloClient({
      uri: process.env.REACT_APP_METAGQL_URL,
      cache: new InMemoryCache(),
      headers: {
        'x-api-key': process.env.REACT_APP_METAGQL_API_KEY || '',
        'Content-Type': 'application/json'
      }
    })
  }
  return apolloClient
}
