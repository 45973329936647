import { combineReducers } from 'redux'
import canopyMeta from 'state/slices/app/canopyMeta'
import vaults from 'state/slices/app/vaults'
import brokers from 'state/slices/app/brokers'
import brokerHistory from 'state/slices/app/brokerHistory'
import poolInfo from 'state/slices/app/poolInfo'

export const app = combineReducers({
  canopyMeta,
  vaults,
  brokers,
  brokerHistory,
  poolInfo
})
