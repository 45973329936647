import tooltip from 'assets/tooltips/tooltip.svg'
import closeIcon from 'assets/icons/close.svg'
import redTooltip from 'assets/tooltips/tooltip-red.svg'
import orangeTooltip from 'assets/tooltips/tooltip-orange.svg'

export interface InfoBoxProps {
  color: 'blue' | 'orange' | 'red'
  hideBoxCallback?: () => void
  showBox: boolean
  text: string
  fullWidth?: boolean
}

const tooltipMap = {
  blue: tooltip,
  orange: orangeTooltip,
  red: redTooltip
}

const InfoBox = ({ color, hideBoxCallback, showBox, fullWidth, text }: InfoBoxProps) => {
  const hideBoxClass = !showBox ? 'hide' : ''
  const tooltipIcon = tooltipMap[color]
  const fullWidthClass = fullWidth ? 'full-width' : ''

  return (
    <div className={`info-box ${color} ${hideBoxClass} ${fullWidthClass}`}>
      {/* <img src={tooltipIcon} alt="tooltip" /> */}
      <p>{text}&nbsp;&nbsp;</p>
      {!!hideBoxCallback && (
        <img src={closeIcon} alt="close" className="clickable" onClick={hideBoxCallback} />
      )}
    </div>
  )
}

export default InfoBox
