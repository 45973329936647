import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { fetchWalletBalances, WalletBalance } from 'state/thunks/fetchWalletBalances'
import { isEmptyOrNil } from 'toolbox/account'

type State = Loadable<WalletBalance[]>
export const initialState = initLoadable<WalletBalance[]>([])

const Balances = createSlice({
  name: 'Balances',
  initialState,
  reducers: {
    resetWalletBalances: (state: State) => {
      state.value = []
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWalletBalances.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchWalletBalances.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export const { resetWalletBalances } = Balances.actions
export default Balances.reducer
export const selectWalletBalances = (s: RootState) => s.user.balances.value
export const selectWalletBalancesBusy = (s: RootState) => s.user.balances.status === 'busy'
export const selectWalletBalancesLoaded = (s: RootState) => s.user.balances.loadedOnce

export const selectLoadedWalletBalances = createSelector(
  [selectWalletBalances, selectWalletBalancesLoaded],
  (bals, loaded) => {
    if (!loaded) {
      return []
    }
    if (isEmptyOrNil(bals)) {
      return []
    }

    return bals
  }
)

export const getWalletBalance = (balances: WalletBalance[], coinType: string) => {
  if (isEmptyOrNil(balances)) {
    return 0
  }

  const balance = balances.find((b) => b.coinType === coinType)
  return balance ? balance.scaledAmount : 0
}
