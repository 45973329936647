import SearchBar, { SearchBarProps } from 'components/common/SearchBar'
import Dropdown from 'components/common/Dropdown'
import { VaultList } from 'components/explore/VaultList'
import { useSelector } from 'react-redux'
import {
  selectExploreInput,
  selectExploreSortBy,
  setExploreInput,
  setExploreSortBy,
  sortByOptions
} from 'state/slices/ui/explore'
import { useAppSelector } from 'state/hooks'

export default function Explore() {
  const inputVal = useSelector(selectExploreInput)
  const selectedOption = useAppSelector(selectExploreSortBy)
  const searchProps: SearchBarProps = {
    value: inputVal,
    update: setExploreInput,
    placeHolder: 'Search by token name'
  }

  return (
    <div className="explore">
      <div className="explore-header">
        <SearchBar {...searchProps} />
        <div className="sort-by-container">
          <p className="sort-by">Sorted by:&nbsp;</p>
          <Dropdown
            options={sortByOptions}
            callback={setExploreSortBy}
            selectedOption={selectedOption}
          />
        </div>
      </div>
      <VaultList />
    </div>
  )
}
