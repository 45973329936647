import { ReactNode, useEffect, useState } from 'react'
import { AptosWalletAdapterProvider, AvailableWallets } from '@aptos-labs/wallet-adapter-react'
import { useEnvironment } from 'App'
import { APTOS } from 'state/envConfigs'

export interface WalletProviderProps {
  children: ReactNode
}

const APTOS_WALLETS: AvailableWallets[] = ['Petra', 'Nightly']
const MOVEMENT_WALLETS: AvailableWallets[] = ['Nightly']

function WalletProvider({ children }: WalletProviderProps) {
  const { config } = useEnvironment()
  const [wallets, setWallets] = useState<AvailableWallets[]>(APTOS_WALLETS)

  useEffect(() => {
    const isAptos = config.chain === APTOS
    setWallets(isAptos ? APTOS_WALLETS : MOVEMENT_WALLETS)
  }, [config.chain])

  return (
    <AptosWalletAdapterProvider
      key={wallets.join(',')}
      autoConnect
      optInWallets={wallets}
      onError={(e: Error) => {
        console.log('Wallet Error:', e)
      }}>
      {children}
    </AptosWalletAdapterProvider>
  )
}

export default WalletProvider
