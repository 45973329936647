import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { GradientBoxText } from 'components/common/GradientBoxText'
import { VaultList } from 'components/explore/VaultList'
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { openWalletSignIn } from 'state/slices/ui/wallet'

const Splash: React.FC = () => {
  const { connected } = useWallet()
  const navigate = useNavigate()

  useEffect(() => {
    if (connected) {
      navigate('/explore')
    }
  }, [connected, navigate])

  return (
    <>
      <div className="splash-container">
        <div className="splash">
          <div className="splash-col left">
            <h1>
              Get Defi <GradientBoxText text="yield%" />
            </h1>
            <h2>
              Access a range of professionally curated DeFi investment strategies from one unified
              hub.
            </h2>
            <button className="btn primary type-2" onClick={openWalletSignIn}>
              Connect wallet
            </button>
          </div>
          <div className="splash-col right">
            <div className="inner-right">
              <VaultList minimal scrolling />
            </div>
            {/* <Link to="/explore">View all</Link> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Splash
