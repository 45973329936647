import { useEffect } from 'react'

interface FullModalProps {
  children: React.ReactNode
  open: boolean
  onClose: () => void
}

export function FullModal({ children, open, onClose }: FullModalProps) {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onClose])

  return (
    <div
      className={`full-modal ${open ? ' is-active' : ''}`}
      id="modal"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          onClose()
        }
      }}>
      <div className={`full-modal-content left-in ${open ? 'live' : ''} `}>{children}</div>
    </div>
  )
}
