import { createAsyncThunk } from '@reduxjs/toolkit'
import { dismissNotifyThrow, logToastThrow, toastLoad } from 'toolbox/toast'
import {
  PacketResponse,
  SignAndSubmitTransactionCallback,
  SPortfolio,
  TransactionArgs,
  UnifiedVault
} from 'state/types'
import { postTransactionRefresh } from 'state/fetch'
import { FormTab, setFormOpenState } from 'state/slices/ui/form'
import { scaleUp } from 'toolbox/format'
import {
  DEPOSIT_ACTION,
  TxAction,
  txStatusIdle,
  txStatusPending,
  WITHDRAW_ACTION
} from 'state/slices/ui/transaction'
import { Aptos, Network } from '@aptos-labs/ts-sdk'
import {
  buildCurrentPortfolioBasicState,
  fetchPacket,
  fetchPortfolioWithRisk,
  signAndSubmitter,
  updateSuccessStateAndWait
} from 'toolbox/aptos-util'
import { CoinTypeWithDecimals } from 'state/slices/app/vaults'

export const WITHDRAW = 'withdraw'
export const SUPPLY_COLLATERAL = 'supply_collateral'
export const BORROW = 'borrow'
export const REPAY = 'repay'

export const DEPOSIT_TAB = 'Deposit'
export const WITHDRAW_TAB = 'Withdraw'

export type TxTypeBroker = typeof WITHDRAW | typeof SUPPLY_COLLATERAL

export const tabToType: { [key: string]: TxTypeBroker } = {
  [WITHDRAW_TAB]: WITHDRAW,
  [DEPOSIT_TAB]: SUPPLY_COLLATERAL
}

export const typeToTab: { [key: string]: FormTab } = {
  [WITHDRAW]: WITHDRAW_TAB,
  [SUPPLY_COLLATERAL]: DEPOSIT_TAB
}

const TxTypeToActionMap: { [key in TxTypeBroker]: TxAction } = {
  [WITHDRAW]: WITHDRAW_ACTION,
  [SUPPLY_COLLATERAL]: DEPOSIT_ACTION
}

export interface TxReqPayloadBroker {
  formTab: FormTab
  amount: number
  vault: UnifiedVault
  address: string
  signAndSub: SignAndSubmitTransactionCallback
  envNetwork: Network
  API_URL: string
  aptos: Aptos
  rootAddr: string
  echelonRootAddress: string
  nodeUrl: string
  coinTypeWithDecimals: CoinTypeWithDecimals[]
}

export const doTxBroker = createAsyncThunk(
  'broker/doTxBroker',
  async (payload: TxReqPayloadBroker): Promise<any> => {
    toastLoad('Building transaction...')
    txStatusPending({ type: payload.formTab, info: 'Signing transaction...' })

    const { rootAddr, envNetwork, aptos, API_URL } = payload

    if (!rootAddr || !envNetwork || !aptos || !API_URL) {
      console.log('rootAddress: ', rootAddr)
      console.log('Env network: ', envNetwork)
      console.log('aptosSDK: ', aptos)
      console.log('API_URL: ', API_URL)
      logToastThrow(
        'Missing required system configuration',
        'Check the console for more information'
      )
    }

    const { vault, amount, address } = payload
    if (!vault || !amount || !address) {
      logToastThrow(
        'Missing arguments',
        'The transaction request is missing required arguments, please try again'
      )
    }

    const { coinType, decimals, totalDeposit, totalShares, assetName } = vault

    const freshPortfolioState = await fetchPortfolioWithRisk(address, API_URL as string)
    if (!freshPortfolioState) {
      dismissNotifyThrow(
        'Portfolio Fetch Failed',
        `Failed to fetch a fresh portfolio for: ${address}`
      )
    }

    const currentPortfolioState = buildCurrentPortfolioBasicState(freshPortfolioState as SPortfolio)
    const exchangeRate = totalDeposit / totalShares || 1
    const depNoteAmount = scaleUp(amount, decimals) / exchangeRate
    const scaledAmount = scaleUp(amount, decimals)

    const txType = tabToType[payload.formTab]
    let amountValue = ''
    switch (txType) {
      case SUPPLY_COLLATERAL:
        amountValue = Math.floor(scaledAmount).toString()
        break
      case WITHDRAW:
        amountValue = Math.floor(depNoteAmount).toString()
        break

      default:
        logToastThrow('Invalid transaction type', `The transaction of type ${txType} is invalid`)
    }

    const txArgs: TransactionArgs = {
      brokerName: assetName,
      amount: amountValue,
      network: 'Aptos',
      signerPubkey: address,
      currentPortfolioState
    }

    //execute all transaction steps
    const packet = await fetchPacket({ txType, txArgs, url: API_URL })
    const action = txType.toLowerCase()
    const hash = await signAndSubmitter({
      packet: packet as PacketResponse,
      coinType,
      txType,
      address,
      rootAddress: rootAddr as string,
      signAndSub: payload.signAndSub
    })
    await updateSuccessStateAndWait({
      hash: hash.hash,
      aptos,
      action
    })
    postTransactionRefresh(
      address,
      payload.coinTypeWithDecimals,
      API_URL,
      aptos,
      payload.echelonRootAddress,
      payload.nodeUrl
    )
    setFormOpenState(false)
    txStatusIdle()
  }
)
